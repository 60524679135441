/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isValidUsertoConfigurations } from '../../../actions/auth.action';
import { getTokenExternalSourceAction } from '../../../actions/general.action';
import CreatePackage from './CreatePackage';
import ConfigurePackage from './ConfigurePackage';

const useStyles = makeStyles((theme) => ({
  paper: {
    background: 'linear-gradient(#FFFFFFCC, #ffffff)',
    borderRadius: '30px',
    opacity: 1,
    padding: '5px',
    marginTop: '20px',
  },
  tabs: {
    paddingRight: 20,
    paddingLeft: 20,
  },
  tab: {
    borderBottom: '1px solid #ccc',
    textTransform: 'none',
    fontWeight: 600,
    width: '100%',
  },
  lastTtab: {
    borderBottom: '1px solid #ccc',
    textTransform: 'none',
    fontWeight: 1500,
    width: '100%',
    maxWidth: 'none',
  },
  paperTab: {
    backgroundColor: '#fff',
    margin: 20,
    borderRadius: 10,
    border: '1px solid #32ACDE',
  },
  title: {
    ...theme.backOffice.configurations.title,
    fontWeight: 600,
    fontSize: '26px',
    paddingTop: '15px',
    paddingLeft: '20px',
  },
  accessDeniedContainer: {
    height: 300,
  },
  accessDenied: {
    color: '#272727',
    fontWeight: 500,
    fontSize: '20px',
    paddingTop: '15px',
    paddingLeft: '18px',
  },
}));

const Packages = (props) => {
  const classes = useStyles();
  const {
    getTokenExternalSource, appName, decodedToken,
  } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const [showFunctionality, setShowFunctionality] = useState(false);
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (isValidUsertoConfigurations(decodedToken, 'PACKAGES') && appName === 'license') setShowFunctionality(true);
    const appsAdminToken = sessionStorage.getItem('tokenAdminIbuho');
    if (!appsAdminToken) getTokenExternalSource();
  }, []);

  function tapSelected(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(propsPanel) {
    const {
      children, valueTapPanel, index,
    } = propsPanel;
    return (
      <div
        role="tabpanel"
        hidden={valueTapPanel !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {valueTapPanel === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  const handleChange = (value, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Container component="main" maxWidth={isMd ? 'md' : 'lg'}>
        <Paper elevation={3} className={classes.paper}>
          <Typography color="primary" variant="h4" className={classes.title}>Paquetes</Typography>
          {
            showFunctionality
              ? (
                <>
                  <Tabs
                    value={value}
                    indicatorColor="secondary"
                    textColor="secondary"
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                    className={classes.tabs}
                  >
                    <Tab className={classes.tab} label="Crear paquetes" {...tapSelected(0)} />
                    <Tab className={classes.tab} label="Configurar paquetes" {...tapSelected(1)} />
                    <Tab className={classes.lastTtab} disabled />
                  </Tabs>

                  <Paper elevation={3} className={classes.paperTab}>
                    <TabPanel valueTapPanel={value} index={0}>
                      <CreatePackage />
                    </TabPanel>
                    <TabPanel valueTapPanel={value} index={1}>
                      <ConfigurePackage />
                    </TabPanel>
                  </Paper>
                </>
              ) : (
                <Box className={classes.accessDeniedContainer}>
                  <Typography color="primary" variant="h4" className={classes.accessDenied}>
                    No tienes los suficientes privilegios para realizar esta acción.
                  </Typography>
                </Box>
              )
          }
        </Paper>
      </Container>
    </>
  );
};

const mapStateToProps = ({ general, auth }) => ({
  appName: general.appName,
  decodedToken: auth.decodedToken,
});

const mapDispatchToProps = {
  getTokenExternalSource: getTokenExternalSourceAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
