/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
import jwtDecode from 'jwt-decode';
import { TOKEN_PREFIX } from '../config/config';

export const assignAuthTokenAndRefreshToken = (tokenAndRefreshTokenJson) => {
  if (tokenAndRefreshTokenJson) {
    sessionStorage.setItem('appsUserToken', TOKEN_PREFIX + tokenAndRefreshTokenJson.token);
    sessionStorage.setItem('appsUserRefreshToken', tokenAndRefreshTokenJson.refreshToken);
  } else {
    sessionStorage.removeItem('appsUserToken');
    sessionStorage.removeItem('appsUserRefreshToken');
  }
};

export const isValidTokenAdmin = (token) => {
  const jwt = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  if (jwt.exp < currentTime) {
    console.warn('authUtils: Token removed');
    sessionStorage.removeItem('tokenAdminIbuho');
    return false;
  }
  return true;
};

export const getLettersAvatar = (userInfo) => `${userInfo.name.toUpperCase().charAt(0)}${userInfo.surname.toUpperCase().charAt(0)}`;

export const isAccesToRole = (role, route, enqueueSnackbar) => {
  switch (route) {
    case '/payments':
      if (role === 'ROL_VALIDADOR') return true;
      break;
    default:
      return true;
  }

  enqueueSnackbar('Perfil de usuario no es válido para esta opción', {
    variant: 'warning',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  });

  return false;
};
