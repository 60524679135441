import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'; // Importa makeStyles
import {
  Table, TableBody, TableCell, TableContainer, TableRow, IconButton, Collapse, Box,
  Typography,
} from '@material-ui/core';
import DetailContract from './DetailContract';
import FirmayaServicesDashboardDark from '../../../assets/img/dashboard/firmaya/dashboard_dark.svg';
import FirmayaServicesDashboardLight from '../../../assets/img/dashboard/firmaya/dashboard_light.svg';
import MyselfcertServicesDashboardDark from '../../../assets/img/dashboard/myselfcert/dashboard_dark.svg';
import MyselfcertServicesDashboardLight from '../../../assets/img/dashboard/myselfcert/dashboard_light.svg';
import BiometrixIaServicesDashboardDark from '../../../assets/img/dashboard/biometrixia/dashboard_dark.svg';
import BiometrixIaServicesDashboardLight from '../../../assets/img/dashboard/biometrixia/dashboard_light.svg';
import BiometriaServicesDashboardDark from '../../../assets/img/dashboard/biometria/dashboard_dark.svg';
import BiometriaServicesDashboardLight from '../../../assets/img/dashboard/biometria/dashboard_light.svg';
import CorreoCEServicesDashboardDark from '../../../assets/img/dashboard/correoce/dashboard_dark.svg';
import CorreoCEServicesDashboardLight from '../../../assets/img/dashboard/correoce/dashboard_light.svg';
import CorreoBCServicesDashboardDark from '../../../assets/img/dashboard/correobc/dashboard_dark.svg';
import CorreoBCServicesDashboardLight from '../../../assets/img/dashboard/correobc/dashboard_light.svg';
import AniServicesDashboardDark from '../../../assets/img/dashboard/ani/dashboard_dark.svg';
import AniServicesDashboardLight from '../../../assets/img/dashboard/ani/dashboard_light.svg';
import GseServicesDashboardLight from '../../../assets/img/dashboard/gse/dashboard_light.svg';
import GseServicesDashboardDark from '../../../assets/img/dashboard/gse/dashboard_dark.svg';
import ArrowDownIcon from '../../../assets/img/iconos/license/ArrowDownIcon.svg';
import ArrowUpIcon from '../../../assets/img/iconos/license/ArrowUpIcon.svg';
import ArrowDownIconDetails from '../../../assets/img/iconos/license/ArrowDownIconDetails.svg';
import ArrowUpIconDetails from '../../../assets/img/iconos/license/ArrowUpIconDetails.svg';

// Definición de los estilos con makeStyles
const useStyles = makeStyles((theme) => ({
  tableCellHead: {
    fontWeight: 'bold',
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: 'white',
    },
    '&:first-child': {
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
    },
    '&:last-child': {
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
    },
    ...theme.miplan.tablePlanTable,
  },
  card: {
    margin: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
    width: '100%',
    borderRadius: '8px',
    backgroundColor: '#F9F9F9',
  },
  tableCell: {
    height: '40px',
    letterSpacing: '0px',
    fontSize: 13,
    '&:last-child': {
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
    },
    '&:first-child': {
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
    },
    ...theme.miplan.tableCellPlanTable,
  },
  tableCellHeadFeatures: {
    fontWeight: 'bold',
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: 'white',
    },
    '&:first-child': {
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
    },
    '&:last-child': {
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
    },
    color: '#7B7B7B',
    backgroundColor: '#F9F9F9',
  },
  table: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  keyboardArrow: {
    '& .MuiIconButton-label': {
      height: '22.29px',
    },
  },
}));

const TableServices = (props) => {
  const { getServicesDataDashboard } = props;
  const classes = useStyles();
  const [open, setOpen] = useState([false, false]);
  const [openPackage, setOpenPackage] = useState([]);

  const handleClick = (index) => {
    const newOpen = [...open];
    newOpen[index] = !newOpen[index];
    setOpen(newOpen);
  };

  const handlePackageClick = (index, serviceIndex) => {
    const newOpenPackage = [...openPackage];
    if (!newOpenPackage[serviceIndex]) {
      newOpenPackage[serviceIndex] = [];
    }
    newOpenPackage[serviceIndex][index] = !newOpenPackage[serviceIndex][index];
    setOpenPackage(newOpenPackage);
  };

  const getLogoService = (serviceName, mode) => {
    switch (serviceName.toLowerCase()) {
      case 'firmaya':
        return mode === 'dark' ? FirmayaServicesDashboardDark : FirmayaServicesDashboardLight;
      case 'myselfcert':
        return mode === 'dark' ? MyselfcertServicesDashboardDark : MyselfcertServicesDashboardLight;
      case 'biometria':
        return mode === 'dark' ? BiometriaServicesDashboardDark : BiometriaServicesDashboardLight;
      case 'biometrixia':
        return mode === 'dark' ? BiometrixIaServicesDashboardDark : BiometrixIaServicesDashboardLight;
      case 'correoce':
        return mode === 'dark' ? CorreoCEServicesDashboardDark : CorreoCEServicesDashboardLight;
      case 'correobc':
        return mode === 'dark' ? CorreoBCServicesDashboardDark : CorreoBCServicesDashboardLight;
      case 'ani':
        return mode === 'dark' ? AniServicesDashboardDark : AniServicesDashboardLight;
      case 'biocapture':
        return mode === 'dark' ? BiometrixIaServicesDashboardDark : BiometrixIaServicesDashboardLight;
      case 'mbssfacial':
        return mode === 'dark' ? BiometrixIaServicesDashboardDark : BiometrixIaServicesDashboardLight;
      default:
        return mode === 'dark' ? GseServicesDashboardDark : GseServicesDashboardLight;
    }
  };

  return (
    <TableContainer>
      <Table
        stickyHeader
        className={classes.table}
        aria-labelledby="tableTitle"
        size="small"
        aria-label="customized table"
        style={{
          tableLayout: 'fixed', paddingTop: '18px', paddingRight: '30px', paddingLeft: '30px', borderSpacing: '0 12px',
        }}
      >
        <TableBody>
          {getServicesDataDashboard.map((service, serviceIndex) => (
            <>
              <TableRow
                role="checkbox"
                colSpan={2}
                style={{ borderBottom: 'none' }}
              >
                <TableCell className={classes.tableCellHead}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={getLogoService(service.serviceName, 'light')}
                      alt="IconLight"
                      style={{
                        marginRight: '20px',
                        marginLeft: '20px',
                        width: '20px',
                        height: '20px',
                        objectFit: 'contain',
                      }}
                    />
                    <Typography classes={{ body1: classes.columnStyleParent }} style={{ fontWeight: 700 }}>
                      {service.name.split(' - ')[1]}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell className={classes.tableCellHead} style={{ display: 'flex', justifyContent: 'end' }}>
                  <IconButton onClick={() => handleClick(serviceIndex)} style={{ color: '#FFFFFF', marginRight: '3px' }} className={classes.keyboardArrow}>
                    {open[serviceIndex] ? <img src={ArrowUpIcon} alt="ArrowUpIcon" /> : <img src={ArrowDownIcon} alt="ArrowDownIcon" />}
                  </IconButton>
                </TableCell>
              </TableRow>
              {open[serviceIndex] && (
                <TableRow>
                  <TableCell colSpan={2} style={{ padding: 0 }}>
                    <Box>
                      <Table
                        stickyHeader
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="customized table"
                        style={{
                          tableLayout: 'fixed',
                          width: '100%',
                          borderSpacing: '0 12px',
                        }}
                      >
                        <TableBody>
                          {service.contractedServices.map((contract, idx) => (
                            <>
                              <TableRow
                                role="checkbox"
                                colSpan={2}
                                style={{ paddingTop: '15px' }}
                              >
                                <TableCell className={classes.tableCellHeadFeatures}>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '6px',
                                        backgroundColor: 'white',
                                        borderRadius: '6px',
                                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                        marginRight: '15px',
                                        marginLeft: '12px',
                                      }}
                                    >
                                      <img
                                        src={getLogoService(service.serviceName, 'dark')}
                                        alt="IconDark"
                                        style={{
                                          width: '20px',
                                          height: '20px',
                                          objectFit: 'contain',
                                        }}
                                      />
                                    </div>
                                    <Typography classes={{ body1: classes.columnStyleParent }} style={{ fontWeight: 700 }}>
                                      {contract.packageDescription}
                                    </Typography>
                                  </div>
                                </TableCell>
                                <TableCell className={classes.tableCellHeadFeatures} style={{ display: 'flex', justifyContent: 'end' }}>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography style={{ fontWeight: 700, color: '#E55200', marginTop: '4px' }}>
                                      Ver detalle
                                    </Typography>
                                    <IconButton onClick={() => handlePackageClick(idx, serviceIndex)} style={{ color: '#E55200' }}>
                                      {openPackage[serviceIndex] && openPackage[serviceIndex][idx] ? (
                                        <img src={ArrowUpIconDetails} alt="ArrowUpIconDetails" />
                                      ) : (
                                        <img src={ArrowDownIconDetails} alt="ArrowDownIconDetails" />
                                      )}
                                    </IconButton>
                                  </div>
                                </TableCell>
                              </TableRow>

                              {openPackage[serviceIndex] && openPackage[serviceIndex][idx] && (
                                <TableRow>
                                  <TableCell className={classes.tableCellHeadFeatures} colSpan={2}>
                                    <Collapse in={openPackage[serviceIndex] && openPackage[serviceIndex][idx]} timeout="auto" unmountOnExit>
                                      <DetailContract
                                        ServiceName={service.serviceName}
                                        PackageCode={contract.packageCode}
                                      />
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              )}
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableServices;
