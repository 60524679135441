import axios from 'axios';
import { API_AUTHENTICATION } from '../config/config';
import { assignAuthTokenAndRefreshToken } from '../helpers/authUtils';
import { setLogOutAction } from './auth.action';

export const invokeInterceptor = (appName) => (dispatch) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;

      if (
        error.response.status === 401
        && originalRequest.url === `${API_AUTHENTICATION}/api/Login/RefreshToken`
      ) {
        dispatch(setLogOutAction());
        return Promise.reject(error);
      }

      if (
        (error.response.status === 401 || error.response.status === 403)
        && !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const refreshTokenEncrypted = sessionStorage.getItem(
          'appsUserRefreshToken',
        );
        const userName = sessionStorage.getItem('userName');
        const refreshTokenData = {
          RefreshToken: refreshTokenEncrypted,
          UserName: userName,
          Appname: appName,
        };
        return axios
          .post(
            `${API_AUTHENTICATION}/api/Login/RefreshToken`,
            refreshTokenData,
          )
          .then((res) => {
            if (res.status === 200) {
              assignAuthTokenAndRefreshToken(res.data.result);
              originalRequest.headers.Authorization = `Bearer ${res.data.result.token}`;
              return axios(originalRequest);
            }
          });
      }
      return Promise.reject(error);
    },
  );
};

export default invokeInterceptor;
