/* eslint-disable no-param-reassign */
import axios from 'axios';
import {
  LOADING,
  GET_ERRORS,
  GET_CUSTOMER_INFORMATION,
  GET_STATUS_PURCHASEORDER,
  VALIDITY_CUSTOMER_EMAIL,
  CLEAR_VALIDITY_CUSTOMER_EMAIL,
  VALIDITY_EMAIL_CODE,
  CLEAR_ERRORS,
  GET_VALIDATION_CODE_ERROR,
  CLEAR_VALIDATION_CODE_ERROR,
  APPROVED_VALIDITY_EMAIL,
  PURCHASE_APPROVED_EMAIL,
  VALIDITY_CHANGE_CUSTOMER,
  CLEAR_VALIDITY_CHANGE_CUSTOMER,
  SET_REFERENCEUUID_PURCHASE_ORDER,
  PURCHASE_SHOW_MESSAGE,
  LOADING_CHECK_PAYMENT,
  REGISTER_IDENTITY_VALIDATION,
} from '../types/purchaseLicense.type';
import {
  SET_LIST_CAR,
} from '../types/home.type';
import { clearGoogleAssistantAction } from './google.action';
import {
  API_LICENCIAMIENTO,
  API_PAYMENTSERVICESVINKEL,
  EPAYCOPAYMENTSSERVICES,
  API_EXTERNAL_SOURCE,
  DEFAULTRESPONSE,
} from '../config/config';
import { CleanCart } from '../helpers/cartUtils';
import { defaultHeaders, capitalizeFirstLetter } from '../helpers/generalUtils';
import { clearLocalStorageStore } from '../helpers/storageUtils';
import emailLogoFirmaya from '../assets/img/activatePlan/email-logo.svg';
import expiredLogoFirmaya from '../assets/img/iconos/firmaya/ExpiredPlan.svg';

let tokenPaymentService = '';

const defaultHeadersAdminIbuhoo = (token) => ({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${token}`,
  },
});

export const setMessageAlertAction = (data) => async (dispatch) => {
  try {
    dispatch({
      type: PURCHASE_SHOW_MESSAGE,
      payload: data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const clearVarsFormRegisterAction = () => async (dispatch) => {
  try {
    dispatch(clearGoogleAssistantAction('Billing'));
    dispatch(clearGoogleAssistantAction('Service'));
    dispatch({ type: PURCHASE_APPROVED_EMAIL, payload: undefined });
  } catch (error) {
    console.error(error);
  }
};

export const sendToPaymentAction = (token, request, customerBilling) => async (
  dispatch,
) => {
  try {
    const urlPaymentServices = `${API_PAYMENTSERVICESVINKEL}${'payment/form'}`;
    const configPaymentServices = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };

    const paymentRequest = {
      fullReference: request.fullReference,
      redirectionUrl: request.redirectionUrl,
      subTotal: request.subTotal,
      taxIva: request.taxIva,
      taxReteIva: request.taxReteIva,
      taxReteIca: request.taxReteIca,
      taxReteFuente: request.taxReteFuente,
      user: {
        firstName: customerBilling.firstName,
        lastName: customerBilling.lastName,
        legalName: customerBilling.personType === 'N' ? null : customerBilling.legalName,
        isLegalClient: customerBilling.personType === 'J',
        documentType: request.customerBillingDocumentType,
        documentNumber: customerBilling.documentNumber,
        cellPhoneNumber: customerBilling.cellphoneNumber,
        email: customerBilling.email,
        departmentCode: customerBilling.departmentId,
        cityCode: customerBilling.cityId,
        address: customerBilling.fulladdress,
        additionalInformation: request.additionalInformation,
      },
      productsList: request.productsList,
      service: request.service,
    };

    const response = await axios.post(
      urlPaymentServices,
      paymentRequest,
      configPaymentServices,
    );

    if (response.status === 200) window.location.href = response.data.result.url;

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    const { response } = error;
    console.error('Error al registrar pago ecommerce Vinkel-->', response);
    dispatch({ type: LOADING, payload: false });
    dispatch({
      type: GET_ERRORS,
      payload: {
        message: DEFAULTRESPONSE.errorRegisterPaymentVinkel,
      },
    });
  }
};

export const sendToePaycoPaymentAction = (token, documentType, documentNumber, code) => async (
  dispatch,
) => {
  try {
    const urlePaycoServices = `${EPAYCOPAYMENTSSERVICES}?paymentId=${code}&signToken=${token}&docType=${documentType}&docNumber=${documentNumber}`;

    window.location.href = urlePaycoServices;

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    const { response } = error;
    console.error('Error al registrar pago ecommerce Vinkel-->', response);
    dispatch({ type: LOADING, payload: false });
    dispatch({
      type: GET_ERRORS,
      payload: {
        message: DEFAULTRESPONSE.errorRegisterPaymentVinkel,
      },
    });
  }
};

export const savePurchaseAction = (
  formData,
  paymentMethod,
  appName,
  history,
  confirmEmail,
  geoCodeBilling,
  geoCodeService,
  activatePlan,
) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING, payload: true });
    dispatch({ type: CLEAR_ERRORS, payload: {} });
    dispatch({ type: CLEAR_VALIDITY_CHANGE_CUSTOMER, payload: undefined });
    dispatch({ type: PURCHASE_SHOW_MESSAGE, payload: undefined });
    dispatch({ type: CLEAR_VALIDITY_CUSTOMER_EMAIL, payload: undefined });
    const {
      home: { cart, promoCode },
    } = getState();
    const cartClean = CleanCart(cart, undefined, appName);
    const userToken = sessionStorage.getItem('appsUserToken');
    const packagesToPurchase = [];
    // listado de productos paquetes en carrito de compras
    cartClean.forEach((item) => packagesToPurchase.push({
      packageId: item.id,
      packageCode: '',
      packageQuantity: item.number,
    }));

    let { emailService } = formData;
    if (confirmEmail) {
      if (confirmEmail !== emailService) {
        emailService = confirmEmail;
      }
    }

    const customerService = {
      personType: formData.personTypeService,
      documentTypeId: formData.documentTypeIdService,
      documentNumber: formData.documentNumberService,
      legalName: formData.personTypeService === 'J' ? formData.legalNameService : null,
      firstName: formData.firstNameService,
      lastName: formData.lastNameService,
      cellphoneNumber: formData.cellphoneNumberService,
      email: emailService,
      departmentId: formData.departmentIdService.value
        ? formData.departmentIdService.value
        : formData.departmentIdService,
      cityId: formData.cityIdService.id
        ? formData.cityIdService.id
        : formData.cityIdService,
      fulladdress: formData.fulladdressService.description,
      legalDocumentTypeId: formData.legalDocumentTypeIdService,
      legalDocumentNumber: formData.legalDocumentNumberService,
      legalFirstName: formData.legalFirstNameService,
      legalLastName: formData.legalLastNameService,
      dv: formData.dvService,
      addressAdditionalInformation: formData.additionalInfoService,
      addressLatGeocode: geoCodeService ? geoCodeService.lat : null,
      addressLngGeocode: geoCodeService ? geoCodeService.lng : null,
    };
    let customerBilling;
    if (formData.personTypeBilling === undefined || formData.personTypeBilling === '') {
      customerBilling = customerService;
    } else {
      customerBilling = {
        personType: formData.personTypeBilling,
        documentTypeId: formData.documentTypeIdBilling,
        documentNumber: formData.documentNumberBilling,
        legalName: formData.personTypeBilling === 'J' ? formData.legalNameBilling : null,
        firstName: formData.firstNameBilling,
        lastName: formData.lastNameBilling,
        cellphoneNumber: formData.cellphoneNumberBilling,
        email: formData.emailBilling,
        departmentId: formData.departmentIdBilling.value
          ? formData.departmentIdBilling.value
          : formData.departmentIdBilling,
        cityId: formData.cityIdBilling.id
          ? formData.cityIdBilling.id
          : formData.cityIdBilling,
        fulladdress: formData.fulladdressBilling.description,
        dv: formData.dvBilling,
        addressAdditionalInformation: formData.additionalInfoBilling,
        addressLatGeocode: geoCodeBilling ? geoCodeBilling.lat : null,
        addressLngGeocode: geoCodeBilling ? geoCodeBilling.lng : null,
      };
    }

    const savePurchaseRequest = {
      customerBilling,
      customerService,
      packagesToPurchase,
      promotionCodeUse: promoCode ? promoCode.validPromoCodes : [],
      totalDiscount: 0,
      totalTaxIva: 0,
      totalPurchase: 0,
      userRegisterPurchase: '',
      additionalInfo: formData.additionalInfoBilling,
      paymentMethod,
      appName,
      userToken,
      sendEmailActivationLicense: !(activatePlan && appName === 'firmaya'),
      checkUserActivation: activatePlan,
    };

    const config = defaultHeaders();
    const response = await axios.post(
      `${API_LICENCIAMIENTO}/license/api/PurchaseOrderLicense/RegisterPurchaseOrder`,
      savePurchaseRequest, config,
    );
    if (response.status === 200) {
      const productListArray = [];
      const result = response.data.result.records;
      tokenPaymentService = result.token;
      const paymentRequestResponse = result.paymentRequest;
      const { paymentGateway, epaycoPaymentCode } = result;

      paymentRequestResponse.productsList.forEach((item) => productListArray.push({
        ref: item.reference,
        quantity: item.quantity,
        unitPrice: item.unitPrice,
      }));

      const paymentRequest = {
        fullReference: paymentRequestResponse.fullReference,
        redirectionUrl: paymentRequestResponse.redirectionUrl,
        subTotal: paymentRequestResponse.subTotal,
        taxIva: paymentRequestResponse.taxIva,
        taxReteIva: paymentRequestResponse.taxReteIva,
        taxReteIca: paymentRequestResponse.taxReteIca,
        taxReteFuente: paymentRequestResponse.taxReteFuente,
        productsList: productListArray,
        customerBillingDocumentType: result.customerBillingDocumentType,
        additionalInformation: formData.additionalInfoBilling,
        service: paymentRequestResponse.service,
      };

      // remueve de localstorage
      clearLocalStorageStore();

      const responseOrder = response.data.result.records;
      if (paymentMethod === 'EN_LINEA') {
        // Integración pasarela de pagos "PAGUE A TIEMPO"
        if (paymentGateway === 'PAGUEATIEMPO') {
          dispatch(
            sendToPaymentAction(
              tokenPaymentService,
              paymentRequest,
              customerBilling,
            ),
          );
          dispatch({ type: LOADING, payload: true });
        } else if (paymentGateway === 'EPAYCO') {
          // Integración pasarela de pagos "e-Payco"
          dispatch(
            sendToePaycoPaymentAction(
              tokenPaymentService,
              responseOrder.documentType,
              responseOrder.documentNumber,
              epaycoPaymentCode,
            ),
          );
          dispatch({ type: LOADING, payload: true });
        }
      } else if (paymentMethod === 'SIN_PAGO' && !activatePlan) {
        history.push(
          `/registerPurchaseOrder/statusOrder/${response.data.result.records.referenceUuid}`,
        );
      } else if (paymentMethod === 'SIN_PAGO' && activatePlan) {
        dispatch({
          type: SET_REFERENCEUUID_PURCHASE_ORDER,
          payload: response.data.result.records.referenceUuid,
          code: responseOrder.code,
        });

        if (responseOrder.code === 'OK16') {
          const buttonAceptName = `Ir a ${capitalizeFirstLetter(appName)}`;
          dispatch(
            setMessageAlertAction({
              category: 'activatePlan',
              typeMsg: 'Success',
              tittleMsg: '¡Ya tienes una cuenta activa!',
              message: responseOrder.statusMessage,
              showCloseAlert: true,
              textButtonAcept: buttonAceptName,
            }),
          );
        }
        dispatch({ type: SET_LIST_CAR, payload: [] });
        dispatch({ type: LOADING, payload: false });
      } else if (paymentMethod === 'EFECTIVO') {
        dispatch({
          type: SET_REFERENCEUUID_PURCHASE_ORDER,
          payload: response.data.result.records.referenceUuid,
          code: responseOrder.code,
        });
        dispatch(
          setMessageAlertAction({
            category: 'registerCashPurchase',
            typeMsg: 'Success',
            tittleMsg: '¡Perfecto!',
            message: 'Se ha enviado la orden de pedido a tu correo electrónico',
          }),
        );
        dispatch({ type: SET_LIST_CAR, payload: [] });
        dispatch({ type: LOADING, payload: false });
      }
      dispatch(clearVarsFormRegisterAction());
    } else {
      console.log('error register purchase order', response);
    }
  } catch (error) {
    const { response } = error;
    console.log('error', response);
    dispatch({ type: LOADING, payload: false });
    const jsonData = response?.data?.result?.responseData
      ? response?.data?.result.responseData
      : null;
    let responseData = {};
    if (jsonData) {
      responseData = JSON.parse(jsonData);
    }
    dispatch({
      type: GET_ERRORS,
      payload: {
        message: response?.data?.statusMessage
          ? response.data.statusMessage
          : DEFAULTRESPONSE.errorRegisterPurchaseOrder,
        code: responseData?.Code
          ? responseData.Code
          : '',
        minDateToRenewLicense: responseData?.MinDateToRenewLicense
          ? responseData.MinDateToRenewLicense
          : '',
      },
    });
  }
};

export const getCustomerInformationAction = (data, typeLoad) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    const response = await axios.post(
      `${API_LICENCIAMIENTO}/license/api/PurchaseOrderLicense/GetCustomerInformation`,
      data, config,
    );
    dispatch({
      type: GET_CUSTOMER_INFORMATION,
      payload: response.data.result.records,
      typeLoad,
    });
  } catch (error) {
    console.error(error);
  }
};

export const getStatusOrderAction = (referenceUuid, appName, countValidation) => async (
  dispatch,
) => {
  try {
    if (countValidation === 0 || countValidation === -1) {
      dispatch({ type: LOADING, payload: true });
    } else {
      dispatch({ type: LOADING_CHECK_PAYMENT, payload: true });
    }
    dispatch({ type: CLEAR_ERRORS, payload: {} });
    const config = defaultHeaders();
    Object.assign(config.headers, {
      countValidatePayment: countValidation,
    });
    const response = await axios.get(
      `${API_LICENCIAMIENTO}/license/api/PurchaseOrderLicense/GetStatusPurchaseOrder/${referenceUuid}/${appName}`, config,
    );
    dispatch({
      type: GET_STATUS_PURCHASEORDER,
      payload: response.data.result.records,
    });
    dispatch({ type: LOADING, payload: false });
    dispatch({ type: LOADING_CHECK_PAYMENT, payload: false });
  } catch (error) {
    console.error(error);
  }
};

const callcheckDBDocument = async (typeDoc, doc, appName) => {
  try {
    const config = defaultHeaders();
    const responseBD = await axios.get(
      `${API_LICENCIAMIENTO}/license/api/PurchaseOrderLicense/GetRazonSocialByDocTypeAndDoc/${appName}/${typeDoc}/${doc}`, config,
    );
    if (responseBD.status === 200) {
      return responseBD.data.result.records;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const getToken = () => sessionStorage.getItem('tokenAdminIbuho');

const callcheckRuesDocument = async (personType, doc) => {
  try {
    const config = defaultHeadersAdminIbuhoo(getToken());
    Object.assign(config.headers, {
      DocumentNumber: doc,
      StatusInfo: 'active',
      GetRawInfo: false,
    });

    // API_external_source
    const response = await axios.get(
      `${API_EXTERNAL_SOURCE}/externalsources/api/rues`,
      config,
    );

    if (response.status === 200) {
      return response.data.result;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getNameByTypeDocAndDocAction = (
  personType,
  typeDoc,
  doc,
  appName,
) => async () => {
  try {
    let result = null;
    if (personType && typeDoc && doc) {
      result = await callcheckDBDocument(typeDoc, doc, appName);
      if (result === null) {
        if (appName === 'bemyself') {
          return result;
        }
        result = await callcheckRuesDocument(personType, doc);
      }
    }
    return result;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const checkIsValidEmailAction = (email, resendCode, appName) => async (
  dispatch,
) => {
  try {
    dispatch({ type: VALIDITY_EMAIL_CODE, payload: undefined });
    dispatch({ type: CLEAR_VALIDITY_CUSTOMER_EMAIL, payload: undefined });
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pEmail: email,
      pResendEmail: resendCode,
      pAppName: appName,
    });
    const response = await axios.get(
      `${API_LICENCIAMIENTO}/license/api/purchaseorderlicense/checkisvalidemail`, config,
    );
    dispatch({
      type: VALIDITY_CUSTOMER_EMAIL,
      payload: response.data.result.approvalMail,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.error(error);
  }
};

export const validationMailCodeAction = (email, code, setLoading) => async (
  dispatch,
) => {
  try {
    setLoading(true);
    dispatch({ type: CLEAR_VALIDATION_CODE_ERROR, payload: undefined });

    const validityCodeRequest = {
      email,
      code,
    };
    const config = defaultHeaders();
    const response = await axios.post(
      `${API_LICENCIAMIENTO}/license/api/purchaseorderlicense/validationmailcode`,
      validityCodeRequest, config,
    );

    const { approved } = response.data.result;
    dispatch({
      type: VALIDITY_EMAIL_CODE,
      payload: approved,
    });

    if (!approved) {
      dispatch({
        type: GET_VALIDATION_CODE_ERROR,
        payload: response.data.statusMessage,
      });
    } else {
      dispatch({
        type: PURCHASE_APPROVED_EMAIL,
        payload: email,
      });
    }

    setLoading(false);
  } catch (error) {
    console.error(error);
    setLoading(false);
  }
};

export const changeConfirmEmailAction = (email) => async (
  dispatch,
) => {
  try {
    dispatch({
      type: PURCHASE_APPROVED_EMAIL,
      payload: email,
    });
  } catch (error) {
    console.error(error);
  }
};

export const approveMailCodeAction = () => async (
  dispatch,
) => {
  try {
    dispatch({ type: APPROVED_VALIDITY_EMAIL, payload: true });
    dispatch(
      setMessageAlertAction({
        category: 'approveMailCode',
        typeMsg: 'Success',
        tittleMsg: '¡Verificación Exitosa!',
        message: '',
      }),
    );
  } catch (error) {
    console.error(error);
  }
};

export const validityChangeCustomerAction = (
  documentNumberService,
  documentNumberServiceNew,
) => async (
  dispatch,
) => {
  try {
    dispatch({ type: CLEAR_VALIDITY_CHANGE_CUSTOMER, payload: undefined });

    let isEqualCustomer = true;
    if (documentNumberService && documentNumberServiceNew) {
      if (documentNumberService !== documentNumberServiceNew) {
        isEqualCustomer = false;
      }
    }

    dispatch({
      type: VALIDITY_CHANGE_CUSTOMER,
      payload: isEqualCustomer,
    });
  } catch (error) {
    console.error(error);
  }
};

export const resetFormRegisterAction = () => async (dispatch) => {
  try {
    dispatch({ type: PURCHASE_SHOW_MESSAGE, payload: undefined });
    dispatch({ type: PURCHASE_APPROVED_EMAIL, payload: undefined });
    dispatch({ type: VALIDITY_CHANGE_CUSTOMER, payload: undefined });
    dispatch({ type: CLEAR_ERRORS, payload: undefined });
    dispatch(clearVarsFormRegisterAction());
  } catch (error) {
    console.error(error);
  }
};

export const registerIdentityValidationAction = (identityValidationRequest, setLoading) => async (
  dispatch,
) => {
  try {
    setLoading(true);
    dispatch({ type: CLEAR_VALIDATION_CODE_ERROR, payload: undefined });
    const config = defaultHeaders();
    const response = await axios.post(
      `${API_LICENCIAMIENTO}/license/api/purchaseorder/register-identity-validation`,
      identityValidationRequest, config,
    );

    if (response.status === 200) {
      const { code } = response.data.result;
      if (code === 'OK01') {
        dispatch({
          type: REGISTER_IDENTITY_VALIDATION,
          payload: true,
        });
      }
      dispatch(
        setMessageAlertAction({
          firstImage: emailLogoFirmaya,
          firstMessage: 'No dudamos que seas tú...',
          secondMessage: `Solo necesitamos que te dirijas al correo que registraste para validarlo 
          y continuar con el proceso de compra.`,
          textInButton: 'Entendido',
        }),
      );
    } else {
      dispatch({
        type: REGISTER_IDENTITY_VALIDATION,
        payload: false,
      });
      dispatch(
        setMessageAlertAction({
          /* Provisional logo for error message!!! */
          firstImage: expiredLogoFirmaya,
          firstMessage: 'No hemos validado tu información',
          secondMessage: 'Revisa los datos ingresados y vuelve a intentarlo',
          textInButton: 'Entendido',
        }),
      );
    }
    setLoading(false);
  } catch (error) {
    console.error(error);
    setLoading(false);
    dispatch({
      type: REGISTER_IDENTITY_VALIDATION,
      payload: false,
    });
    dispatch(
      setMessageAlertAction({
        /* Provisional logo for error message!!! */
        firstImage: expiredLogoFirmaya,
        firstMessage: 'Lo sentimos.',
        secondMessage: `No hemos podido procesar tu solicitud de validación de identidad, 
            por favor inténtalo más tarde.`,
        textInButton: 'Entendido',
      }),
    );
  }
};
