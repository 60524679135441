/* eslint-disable no-return-assign */
/* eslint-disable prefer-template */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
import {
  URL_WEB_COLFACTURA,
  URL_WEB_COLNOMINA,
  URL_WEB_FIRMAYA,
  URL_WEB_FIRMAMAIL,
  URL_WEB_IBUHOO,
} from '../config/config';

const tenants = [
  { name: 'myselfcert' },
  { name: 'letmicert' },
];

/* --------------------------------------------- */
/* --- Funcion calcular digito de vereficacion -- */
/* --------------------------------------------- */
export const calculateCheckDigit = (nit) => {
  if (nit && !isNaN(nit)) {
    let vpri = [];
    let x;
    let s;
    let z = [];

    vpri = new Array(16);
    z = nit.length;

    vpri[1] = 3;
    vpri[2] = 7;
    vpri[3] = 13;
    vpri[4] = 17;
    vpri[5] = 19;
    vpri[6] = 23;
    vpri[7] = 29;
    vpri[8] = 37;
    vpri[9] = 41;
    vpri[10] = 43;
    vpri[11] = 47;
    vpri[12] = 53;
    vpri[13] = 59;
    vpri[14] = 67;
    vpri[15] = 71;

    x = 0;
    s = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < z; i++) {
      s = nit.substr(i, 1);

      x += s * vpri[z - i];
    }

    s = x % 11;

    return s !== 1 && s !== 0 ? 11 - s : s;
  }
  return '';
};

export const tryParseInt = (value, defaultValue) => {
  let retValue = defaultValue;
  if (value !== null) {
    if (value.length > 0) {
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(value)) {
        // eslint-disable-next-line radix
        retValue = parseInt(value);
      }
    }
  }
  return retValue;
};

/* --------------------------------------------- */
/* --- Obtener nombre aplicacion -- */
/* --------------------------------------------- */
export const getApplicationName = (appName) => {
  let applicationName;
  switch (appName) {
    case 'colfactura':
      applicationName = 'Colfactura';
      break;
    case 'firmaya':
      applicationName = 'FirmaYa';
      break;
    case 'firmamail':
      applicationName = 'Firma Mail';
      break;
    case 'superguarda':
      applicationName = 'Super Guarda';
      break;
    case 'license':
      applicationName = 'Licenciamiento';
      break;
    case 'bemyself':
      applicationName = 'bemyself';
      break;
    default:
      applicationName = 'License';
  }
  return applicationName;
};

/* --------------------------------------------- */
/* --- Default Headers -- */
/* --------------------------------------------- */
export const defaultHeaders = () => ({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `${sessionStorage.getItem('appsUserToken')}`,
  },
});

/* --------------------------------------------- */
/* --- Redondeo de valor a numero entero mas cercano -- */
/* --------------------------------------------- */
export const roundValue = (value) => Math.round(value);

/* --------------------------------------------- */
/* --- Redondeo de valor a 50 mas cercano -- */
/* --------------------------------------------- */
export const roundValue50 = (value) => {
  if (value > 100) {
    return Math.round(value / 50) * 50;
  }
  return value;
};
/* --------------------------------------------- */
/* --- Redondeo de valor a 100 mas cercano -- */
/* --------------------------------------------- */
export const roundValue100 = (value) => {
  if (value > 100) {
    return Math.round(value / 100) * 100;
  }
  return value;
};

/**
 *
 * @param {*} value Valor a redondear
 * @param {*} precision Precision a cantidad de decimales
 * @returns
 */
export const roundDecimalValue = (value, precision) => {
  precision = precision === undefined ? 0 : precision;
  const newValue = parseFloat(value.toFixed(precision));
  return parseFloat(newValue);
};

/**
 *
 * @param {*} value Valor a redondear
 * @param {*} precision Precision a cantidad de decimales
 * @returns
 */
// eslint-disable-next-line arrow-body-style
export const roundValuePrecision = (value, precision) => {
  return +(`${Math.round(`${value}e+${precision}`)}e-${precision}`);
};

/**
 *
 * @param {*} value Valor a truncar
 * @param {*} digits digitos de decimales a truncar
 * @returns
 */
export const truncateValue = (value, digits) => {
  value = isNaN(value) ? 0 : value;
  var re = new RegExp('^-?\\d+(?:.\\d{0,' + (digits || -1) + '})?');
  return parseFloat(value.toString().match(re)[0]);
};

/* --------------------------------------------- */
/* --- Funcion Remover Acentos todos -- */
/* --------------------------------------------- */
export const removeAccents = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

/* --------------------------------------------- */
/* --- Funcion Remover Acentos Tiles -- */
/* --------------------------------------------- */
export const removeAccentsTildes = (str) => {
  const accents = {
    á: 'a',
    é: 'e',
    í: 'i',
    ó: 'o',
    ú: 'u',
    Á: 'A',
    É: 'E',
    Í: 'I',
    Ó: 'O',
    Ú: 'U',
  };
  return str.split('').map((letter) => accents[letter] || letter).join('').toString();
};

/**
 * Metodo orden de lista
 * @param {*} field campo de orden
 * @param {*} reverse modo de ordenacion
 * @param {*} primer criterio campo orden
 * @returns retorna lista ordenada
 */
export const sortBy = (field, reverse, primer) => {
  const key = primer ? function (x) {
    return primer(x[field]);
  } : function (x) {
    return x[field];
  };

  reverse = !reverse ? 1 : -1;

  return function (a, b) {
    return (a = key(a), b = key(b), reverse * ((a > b) - (b > a)));
  };
};

/**
 * Metodo redirecciona a Url Aplicacion
 * @param {*} appName variable appname
 * @returns retorna URL de aplicacion
 */
export const redirectUrlAppName = (appName) => {
  switch (appName) {
    case 'colfactura':
      return URL_WEB_COLFACTURA;
    case 'colnomina':
      return URL_WEB_COLNOMINA;
    case 'firmaya':
      return URL_WEB_FIRMAYA;
    case 'firmamail':
      return URL_WEB_FIRMAMAIL;
    case 'superguarda':
      return URL_WEB_FIRMAYA;
    default:
      return URL_WEB_IBUHOO;
  }
};

/**
 * Metodo capitalizar texto
 * @param {*} string texto a convertir
 * @returns retorna texto a convertir
 */
export const capitalizeFirstLetter = (value) => {
  if (value === 'colnomina') {
    value = 'Colfactura Nómina';
  } else if (value === 'bemyself') {
    return value;
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
};

/**
 * Metodo para parametrizar funciones de las aplicaciones
 * @param {*} list texto a convertir
 * @returns retorna un objeto
 */
export const paramaterApplication = (parameters) => {
  const result = {};

  parameters.forEach((parameter) => {
    let value;
    try {
      const parsedValue = JSON.parse(parameter.parameterValue);
      // eslint-disable-next-line valid-typeof
      if (typeof parsedValue.value === parsedValue.type) {
        value = parsedValue.value;
      } else {
        throw new Error('Invalid type');
      }
    } catch (error) {
      Error(`Error parsing value for parameter ${parameter.parameterCode}: ${error}`);
      return; // Skip this parameter
    }

    result[parameter.parameterCode] = value;
  });

  return result;
};

export const getTenantByRole = (decodedToken) => {
  const rol = decodedToken.LICENSE_ROLE;
  const isArrayRoles = Array.isArray(rol);
  let accessRoleMenu = 'MYSELFCERT';
  if (!isArrayRoles) {
    const tenant = tenants.find((x) => rol.toLowerCase().includes(x.name.toLowerCase()));
    if (tenant) {
      accessRoleMenu = tenant.name.toUpperCase();
    }
  } else {
    const tenant = tenants.find((x) => rol.some((r) => r.toLowerCase().includes(x.name.toLowerCase())));
    if (tenant) {
      accessRoleMenu = tenant.name.toUpperCase();
    }
  }
  return accessRoleMenu;
};
