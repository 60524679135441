/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { isValidUsertoConfigurations } from '../../../actions/auth.action';
import { getTokenExternalSourceAction } from '../../../actions/general.action';

import {
  URL_REPORT_GESTION_SERVICIO,
  URL_REPORT_SOPORTE_CLIENTE,
  URL_REPORT_CODIGOS_PROMOCIONALES,
  URL_REPORT_CONSUMO,
} from '../../../config/config';
import { reportConfig } from './reportConfig';

import ReportServiceIcon from '../../../assets/img/iconos/license/ReportServiceIcon.svg';
import ReportSupportUserIcon from '../../../assets/img/iconos/license/ReportSupportUserIcon.svg';
import ReportPromoCodeIcon from '../../../assets/img/iconos/license/ReportPromoCodeIcon.svg';
import ReportConsumoIcon from '../../../assets/img/iconos/license/ReportConsumo.svg';

const useStyles = makeStyles((theme) => ({
  paper: {
    background: 'linear-gradient(#FFFFFFCC, #ffffff)',
    borderRadius: '30px',
    opacity: 1,
    padding: '5px',
    marginTop: '20px',
  },
  paperGroup: {
    backgroundColor: '#fff',
    margin: 20,
    borderRadius: 10,
    border: '1px solid #32ACDE',
    padding: 20,
    minHeight: 300,
  },
  title: {
    ...theme.backOffice.configurations.title,
    fontWeight: 600,
    fontSize: '26px',
    paddingTop: '15px',
    paddingLeft: '20px',
  },
  cardsList: {
    display: 'flex',
  },
  cardItemReport: {
    borderRadius: 10,
    minWidth: 130,
    maxWidth: 130,
    minHeight: 130,
    maxHeight: 130,
    backgroundColor: '#1D71B8',
    color: '#fff',
    margin: 5,
    cursor: 'pointer',
  },
  cardContent: {
    textAlign: 'center',
    fontSize: 20,
  },
  imageReport: {
    width: '40px',
  },
  titleReport: {
    fontSize: '16px',
  },
  accessDeniedContainer: {
    height: 300,
  },
  accessDenied: {
    color: '#272727',
    fontWeight: 500,
    fontSize: '20px',
    paddingTop: '15px',
    paddingLeft: '18px',
  },
}));

const Reports = (props) => {
  const classes = useStyles();
  const {
    appName, decodedToken, getTokenExternalSource,
  } = props;

  const [showFunctionality, setShowFunctionality] = useState(false);

  useEffect(() => {
    if (isValidUsertoConfigurations(decodedToken, 'REPORTS') && appName === 'license') {
      setShowFunctionality(true);
    }
    const appsAdminToken = sessionStorage.getItem('tokenAdminIbuho');
    if (!appsAdminToken) getTokenExternalSource();
  }, []);

  /**
   * handle return url report
   * @param {*} event event
   * @param {*} report tipo reporte
   */
  const handleListItemClick = (event, report) => {
    switch (report) {
      case 'GestionServicio':
        window.open(URL_REPORT_GESTION_SERVICIO, '_blank');
        break;
      case 'SoporteCliente':
        window.open(URL_REPORT_SOPORTE_CLIENTE, '_blank');
        break;
      case 'CodigosPromocionales':
        window.open(URL_REPORT_CODIGOS_PROMOCIONALES, '_blank');
        break;
      case 'ReporteConsumo':
        window.open(URL_REPORT_CONSUMO, '_blank');
        break;
      default:
        break;
    }
  };

  /**
   * obtiene Icono reporte
   * @param {*} type tipo reporte
   * @returns retorna icono reporte
   */
  const getIconReport = (type) => {
    switch (type) {
      case 'GestionServicio':
        return ReportServiceIcon;
      case 'SoporteCliente':
        return ReportSupportUserIcon;
      case 'CodigosPromocionales':
        return ReportPromoCodeIcon;
      case 'ReporteConsumo':
        return ReportConsumoIcon;
      default:
        break;
    }
  };

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper elevation={3} className={classes.paper}>
          <Typography color="primary" variant="h4" className={classes.title}>Informes</Typography>
          {
            showFunctionality
              ? (
                <Paper elevation={3} className={classes.paperGroup}>
                  <Box className={classes.cardsList}>
                    {reportConfig.filter((x) => x.disable === false).map((item, index) => (
                      <Card
                        key={index}
                        className={classes.cardItemReport}
                        onClick={(event) => handleListItemClick(event, item.code)}
                      >
                        <CardContent className={classes.cardContent}>
                          <Tooltip title="Ver informe">
                            <img src={getIconReport(item.code)} className={classes.imageReport} alt="img-report" />
                          </Tooltip>
                          <Typography variant="body2" component="p" className={classes.titleReport}>
                            {item.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    ))}
                  </Box>
                </Paper>
              ) : (
                <Box className={classes.accessDeniedContainer}>
                  <Typography color="primary" variant="h4" className={classes.accessDenied}>
                    No tienes los suficientes privilegios para realizar esta acción.
                  </Typography>
                </Box>
              )
          }
        </Paper>
      </Container>
    </>
  );
};

const mapStateToProps = (props) => ({
  appName: props.general.appName,
  decodedToken: props.auth.decodedToken,
});

const mapDispatchToProps = {
  getTokenExternalSource: getTokenExternalSourceAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
