/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import {
  getServicesDataDashboardAction,
} from '../../../actions/license.action';
import Loading from '../../../components/crosscutting/Loading';
import WithoutServices from '../../../assets/img/dashboard/withoutServices.svg';
import TableServices from './TableServices';

const useStyles = makeStyles((theme) => ({
  paper: {
    background: 'linear-gradient(#FFFFFFCC, #ffffff)',
    borderRadius: '30px',
    opacity: 1,
    padding: '5px',
    marginTop: '20px',
  },
  title: {
    ...theme.backOffice.configurations.title,
    fontWeight: 700,
    fontSize: '31px',
    paddingTop: '45px',
    paddingLeft: '20px',
  },
  paperGroup: {
    backgroundColor: '#fff',
    margin: 20,
    borderRadius: 10,
    border: '1px solid #32ACDE',
    padding: 20,
    minHeight: 300,
  },
}));

const Dashboard = (props) => {
  const { loading, getActionServicesDataDashboard, getServicesDataDashboard } = props;
  useEffect(() => {
    getActionServicesDataDashboard();
  }, []);

  const classes = useStyles();

  if (loading) return <Loading />;

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper elevation={3} className={classes.paper}>
          <Typography color="primary" variant="h4" className={classes.title}>Productos adquiridos</Typography>
          <Paper elevation={3} className={classes.paperGroup}>
            {getServicesDataDashboard.length === 0 ? (
              <>
                <Typography
                  variant="h4"
                  style={{
                    display: 'flex', justifyContent: 'center', fontWeight: 700, color: '#E55200', fontSize: '35px', marginTop: '25px',
                  }}
                >
                  ¡Lo sentimos!
                </Typography>
                <Typography
                  variant="h5"
                  style={{
                    display: 'flex', justifyContent: 'center', fontWeight: 600, color: '#6D6E71 ', fontSize: '30px', marginTop: '45px',
                  }}
                >
                  Consulta con tu comercial aún no tienes servicios asociados a tu compañía.
                </Typography>
                <div style={{
                  display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '25px',
                }}
                >
                  <img src={WithoutServices} alt="withoutServices" />
                </div>
              </>
            ) : (
              <TableServices getServicesDataDashboard={getServicesDataDashboard} />
            )}
          </Paper>
        </Paper>
      </Container>
    </>
  );
};

const mapStateToProps = (props) => ({
  loading: props.license.loading,
  getServicesDataDashboard: props.license.getServicesDataDashboard,
});

const mapDispatchToProps = {
  getActionServicesDataDashboard: getServicesDataDashboardAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
