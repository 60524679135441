/* eslint-disable react-hooks/exhaustive-deps  */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import shallowEqual from 'shallowequal';
import CustomInput from '../../../../components/form/CustomInput';
import CustomSelect from '../../../../components/form/CustomSelect';
import CustomCheck from '../../../../components/form/CustomCheck';
import { Rules } from '../../../../helpers/RHFRules';
import { isValidTokenAdmin } from '../../../../helpers/authUtils';
import { getServicesAction } from '../../../../actions/service.action';
import { registerServiceFeatureAction } from '../../../../actions/serviceFeature.action';
import SwitchiOS from '../../../../components/form/SwitchiOS';
import { getUserDataByIdAction } from '../../../../actions/security.action';
import { getTokenExternalSourceAction } from '../../../../actions/general.action';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    borderRadius: 20,
  },
  titleAlert: {
    color: '#003582',
    fontWeight: 700,
    fontSize: 25,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  dialogContent: {
    marginBottom: 20,
  },
  serviceForm: {
    flexDirection: 'row',
  },
  divider: {
    marginBottom: 20,
  },
  heading: {
    fontSize: theme.typography.pxToRem(13),
    height: 12,
    flexBasis: '33.33%',
    flexShrink: 0,
    color: theme.palette.text.secondary,
  },
  paper: {
    backgroundColor: '#0000000D',
  },
  button: {
    marginTop: 10,
    color: '#fff',
    minWidth: 130,
    borderRadius: 10,
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  },
  tamIcon: {
    transform: 'scale(0.85)',
  },
}));

const ModalServiceFeature = (props) => {
  const classes = useStyles();
  const {
    isOpen, onClose, servicesCombo, featureTypes, item, isNew,
    registerServiceFeature, parentFeatures, loading, getUserDataById,
    dataUserCreate, dataUserModify, getTokenExternalSource,
  } = props;
  const defaultValues = {
    serviceTypeId: !isNew && item ? item.serviceTypeId : null,
    serviceFeatureCode: !isNew && item ? item.serviceFeatureCode : '',
    featureDescription: !isNew && item ? item.featureDescription : '',
    featureTypeId: !isNew && item ? item.featureTypeId : '',
    status: isNew ? false : (item && item.idstate === 0),
    principalFeature: isNew ? true : (item && item.featureRelation === null),
    featureRelation: !isNew && item ? item.featureRelation : '',
    featureAlias: !isNew && item ? item.featureAlias : '',
    urlImageFeature: !isNew && item ? item.urlImageFeature : '',
    colorFeature: !isNew && item ? item.colorFeature : '',
    id: isNew ? 0 : item.id,
  };

  const { enqueueSnackbar } = useSnackbar();
  const [openViewMore, setOpenViewMore] = useState(false);
  const [isParentFeature, setIsParentFeature] = useState(false);
  const [userCreate, setUserCreate] = useState(null);
  const [userModify, setUserModify] = useState(null);
  const [parentFeaturesOptions, setParentsFeaturesOptions] = useState([]);

  const {
    handleSubmit, control, errors, setValue, watch, register
  } = useForm({ defaultValues });

  const {
    serviceTypeId, status,
  } = watch(['serviceTypeId', 'status']);

  useEffect(() => {
    const isParent = !!((item && item.featureRelation === null));
    setIsParentFeature(isNew ? true : isParent);
    const tokenAdmin = sessionStorage.getItem('tokenAdminIbuho');
    if (tokenAdmin && isValidTokenAdmin(tokenAdmin)) {
      if (item) {
        getUserDataById(item.createdby, 'CREATE');
        getUserDataById(item.modifiedby, 'MODIFY');
      }
    } else {
      getTokenExternalSource();
    }
  }, []);

  useEffect(() => {
    if (dataUserCreate) {
      setUserCreate(dataUserCreate);
    } else {
      setUserCreate(null);
    }
  }, [dataUserCreate]);

  useEffect(() => {
    if (dataUserModify) {
      setUserModify(dataUserModify);
    } else {
      setUserModify(null);
    }
  }, [dataUserModify]);

  useEffect(() => {
    setValue('featureRelation', '');
    const parentFeaturesCombo = [];
    if (serviceTypeId) {
      parentFeatures
        .filter((x) => x.serviceTypeId === serviceTypeId)
        .forEach((p) => parentFeaturesCombo.push(
          {
            value: p.value,
            label: p.label,
          },
        ));
      if (parentFeaturesCombo.length > 0) {
        setParentsFeaturesOptions(parentFeaturesCombo);
      } else {
        setParentsFeaturesOptions([]);
      }
    }
  }, [serviceTypeId]);

  const handleSaveService = async (data) => {
    const dataSave = {
      ...data,
      status: data.status !== undefined ? data.status : defaultValues.status,
      id: isNew ? 0 : item.id,
    };
    if (!shallowEqual(defaultValues, dataSave)) {
      await registerServiceFeature(dataSave, enqueueSnackbar);
    }
    onClose();
  };

  const handleClickParentFeature = () => {
    setIsParentFeature(!isParentFeature);
    if (!isParentFeature) {
      setValue('featureRelation', '');
    }
  };

  const active = (statusCheked) => {
    setValue('status', statusCheked);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        className={classes.root}
      >
        <DialogTitle>
          <Typography align="left" color="primary" variant="h6">
            {isNew ? 'Crear característica de servicio' : 'Editar característica de servicio'}
          </Typography>
          <IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={onClose}>
            <CloseIcon className={classes.tamIcon} />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleSaveService)}
            className={classes.serviceForm}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomSelect
                  label="Tipo de servicio*"
                  name="serviceTypeId"
                  control={control}
                  options={servicesCombo}
                  error={errors}
                  className={{ boxShadow: '2px 2px 4px #00000033' }}
                  rule={Rules.required}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  label="Código Característica*"
                  name="serviceFeatureCode"
                  control={control}
                  className={{ boxShadow: '2px 2px 4px #00000033' }}
                  error={errors}
                  rule={Rules.required}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  label="Descripción"
                  name="featureDescription"
                  control={control}
                  className={{ boxShadow: '2px 2px 4px #00000033' }}
                  error={errors}
                  multiline
                  rows={4}
                  rule={Rules.required}
                  inputProps={{
                    maxLength: 250,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomSelect
                  label="Tipo de caracteristica*"
                  name="featureTypeId"
                  control={control}
                  className={{ boxShadow: '2px 2px 4px #00000033' }}
                  options={featureTypes}
                  error={errors}
                  rule={Rules.required}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <CustomCheck
                  label="Caracteristica principal"
                  name="principalFeature"
                  control={control}
                  className={{ boxShadow: '2px 2px 4px #00000033' }}
                  handleOnChangeCkecked={handleClickParentFeature}
                  error={errors}
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <CustomSelect
                  label="Característica Relación*"
                  name="featureRelation"
                  control={control}
                  className={{ boxShadow: '2px 2px 4px #00000033' }}
                  options={parentFeaturesOptions}
                  error={errors}
                  disabled={isParentFeature}
                  rule={!isParentFeature ? Rules.required : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  label="Alias Característica"
                  name="featureAlias"
                  control={control}
                  className={{ boxShadow: '2px 2px 4px #00000033' }}
                  error={errors}
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  label="Url Imagen Característica"
                  name="urlImageFeature"
                  control={control}
                  className={{ boxShadow: '2px 2px 4px #00000033' }}
                  error={errors}
                  inputProps={{
                    maxLength: 1000,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  label="Código Color Característica"
                  name="colorFeature"
                  control={control}
                  className={{ boxShadow: '2px 2px 4px #00000033' }}
                  error={errors}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  style={{ marginLeft: '1px' }}
                  control={(
                    <SwitchiOS
                      // Remember use register, this is similar to useState                    
                      {...register("status")}
                      checked={status}
                      onChange={(e) => active(e.target.checked)}
                      name="status"
                      color="primary"
                    />
                  )}
                  label="Activar"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
            {!isNew && !openViewMore
              && (
                <Button
                  edge="false"
                  size="small"
                  style={{ float: 'right' }}
                  color="primary"
                  onClick={() => { setOpenViewMore(true); }}
                >
                  Ver más
                  <ExpandMoreIcon color="primary" />
                </Button>
              )}
            {!isNew && (
              <>
                <p />
                <Collapse in={openViewMore}>
                  <Paper elevation={1} className={classes.paper}>
                    <Typography variant="caption">
                      <strong>Creado por:</strong>
                      {' '}
                      {userCreate ? (userCreate.names) : ''}
                      <br />
                      <strong>Fecha y hora de creación:</strong>
                      {' '}
                      {format(new Date(item.createdon), 'dd/MM/yyyy hh:mm:ss aaaa ')}
                      {' '}
                      <br />
                      <strong>Modificado por:</strong>
                      {' '}
                      {userModify ? (userModify.names) : ''}
                      {' '}
                      <br />
                      <strong>Fecha y hora de modificación:</strong>
                      {' '}
                      {moment(new Date(item.modifiedon)).isAfter(new Date(item.createdon))
                        ? format(new Date(item.modifiedon), 'dd/MM/yyyy hh:mm:ss aaaa ')
                        : ''}
                    </Typography>
                  </Paper>
                </Collapse>
              </>
            )}
            {openViewMore
              && (
                <Button
                  edge="false"
                  size="small"
                  style={{ float: 'right' }}
                  color="primary"
                  onClick={() => { setOpenViewMore(false); }}
                >
                  Ver menos
                  <ExpandLessIcon color="primary" />
                </Button>
              )}
            <Grid container justify="center">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
              >
                {loading && <CircularProgress color="secondary" size={25} />}
                {isNew ? 'Crear' : 'Aceptar'}
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ general, service, serviceFeature, security }) => ({
  serviceTypes: general.serviceTypes,
  featureTypes: general.featureTypes,
  servicesCombo: service.servicesCombo,
  parentFeatures: serviceFeature.parentFeatures,
  loading: service.loading,
  dataUserCreate: security.dataUserCreate,
  dataUserModify: security.dataUserModify,
});

const mapDispatchToProps = {
  registerServiceFeature: registerServiceFeatureAction,
  services: getServicesAction,
  getUserDataById: getUserDataByIdAction,
  getTokenExternalSource: getTokenExternalSourceAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalServiceFeature);
