/* eslint-disable max-len */
/* eslint-disable array-callback-return */
import axios from 'axios';
import {
  LICENSE_LOADING, GET_LICENSES_CUSTOMER, GET_DETAIL_LICENSES_CUSTOMER,
  CLEAR_LICENSES_CUSTOMER, SET_CHIPS_FILTERS_LICENSES, CHANGE_CHIPS_FILTERS_LICENSES,
  ADD_LICENSE_CUSTOMER, GET_CUSTOMER_INFORMATION_LICENSE, GET_LICENSE_INFORMATION, GET_MANAGERS, GET_APP_PARAMETERS_CONTRACTS, GET_CONTRACT_DATA,
  GET_SERVICES_DATA_DASHBOARD,
  GET_DETAIL_CONTRACTS,
} from '../types/license.type';
import { setMessageAlertAction } from './general.action';
import { API_LICENCIAMIENTO, API_SEGURIDAD } from '../config/config';
import { defaultHeaders, sortBy } from '../helpers/generalUtils';
import AddedLicenseSuccessfullyIcon from '../assets/img/iconos/license/AddedLicenseSuccessfullyIcon.svg';

// eslint-disable-next-line import/prefer-default-export
export const getLicenseUsageCustomerAction = (skip, take, filter, dataCustomer) => async (dispatch, getState) => {
  try {
    dispatch({ type: LICENSE_LOADING, payload: true });
    const { license: { licenseCustomer } } = getState();
    const config = defaultHeaders();
    Object.assign(config.headers, {
      pSkip: skip,
      pTake: take,
    });
    const customer = {
      DocumentType: dataCustomer ? dataCustomer.DocumentType : null,
      DocumentNumber: dataCustomer ? dataCustomer.DocumentNumber : null,
      ServiceName: null,
      pFilter: filter,
    };
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/license/getLicenseUsage`, customer, config);

    if (response.status === 200) {
      const { records } = response.data.result;
      if (licenseCustomer) Array.prototype.push.apply(licenseCustomer, records.licenseUsageList);
      const arrUpdated = Object.assign([], licenseCustomer.length === 0 ? records.licenseUsageList : licenseCustomer);
      dispatch({
        type: GET_LICENSES_CUSTOMER,
        payload: arrUpdated,
        rownums: records.countRecords,
        nextSkipLicense: records.nextSkipLicense,
      });
      dispatch({ type: LICENSE_LOADING, payload: false });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: LICENSE_LOADING, payload: false });
    dispatch({
      type: GET_LICENSES_CUSTOMER,
      payload: [],
      rownums: 0,
      nextSkipLicense: 0,
    });
  }
};

export const getClearLicensesCustomerAction = () => async (dispatch) => {
  dispatch({
    type: GET_LICENSES_CUSTOMER,
    payload: [],
    rownums: 0,
    nextSkipLicense: 0,
  });
};

export const getCustomerInformationLicenseAction = (Idcustomer, docType, doc) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    // Object.assign(config.headers, {
    //   customerId: Idcustomer,
    //   DocumentType: docType,
    //   DocumentNumber: doc,
    // });

    const data = {
      CustomerId: Idcustomer,
      DocumentNumber: doc.toString(),
      DocumentType: docType.toString(),
    };
    const response = await axios.post(
      `${API_LICENCIAMIENTO}/license/api/Customer/GetCustomer`,
      data, config,
    );
    if (response.status === 200) {
      dispatch({
        type: GET_CUSTOMER_INFORMATION_LICENSE,
        payload: response.data,
      });
    }
  } catch (error) {
    console.error(error);
    const { response } = error;
    if (response.status === 404) {
      dispatch({
        type: GET_CUSTOMER_INFORMATION_LICENSE,
        payload: response.data,
      });
    }
  }
};

export const setFiltersLicenseAction = (type, service, customer, optionsFilter) => async (dispatch, getState) => {
  try {
    const { license: { chipsFiltersOptions } } = getState();
    let chipsOptions = chipsFiltersOptions;
    // dispatch({ type: CHANGE_CHIPS_FILTERS_LICENSES, payload: [] });

    if (type === 'Load') {
      // let filtersAux = [];
      // licenses.map((item) => {
      if (customer) {
        if (chipsOptions.filter((x) => x.key === customer.documentNumber).length === 0) {
          const customerFilter = {
            id: customer.CustomerId,
            key: customer.documentNumber,
            type: 'Customer',
            value: customer.customerName,
            default: true,
          };
          chipsOptions.push(customerFilter);
          chipsOptions = chipsOptions.sort(sortBy('type', false, (a) => a.toUpperCase()));
        }
        service.licensePlan.map((license) => {
          if (chipsOptions.filter((x) => x.key === license.serviceId).length === 0) {
            const serviceFilter = {
              id: license.purchaseOrderLicenseId,
              key: license.serviceId,
              type: 'Service',
              value: license.serviceName,
              default: true,
            };
            chipsOptions.push(serviceFilter);
            chipsOptions = chipsOptions.sort(sortBy('type', false, (a) => a.toUpperCase()));
          }
        });
      }
    } else if (type === 'Customer') {
      if (customer) {
        chipsOptions = chipsOptions.filter((x) => x.type !== 'Customer');
        const CustomerFilter = {
          id: customer.id,
          key: customer.id,
          type: 'Customer',
          value: customer.text,
          default: true,
        };
        chipsOptions.push(CustomerFilter);
      }
      chipsOptions = chipsOptions.sort(sortBy('type', false, (a) => a.toUpperCase()));

      dispatch({ type: CHANGE_CHIPS_FILTERS_LICENSES, payload: chipsOptions });
    } else if (type === 'Service') {
      Object.values(optionsFilter).forEach((item) => {
        if (chipsOptions.filter((x) => x.key === item.id && x.type === 'Service').length === 0) {
          if (item.filtered === true) {
            const serviceFilter = {
              id: item.id,
              key: item.id,
              type: 'Service',
              value: item.label,
              default: false,
            };
            chipsOptions.push(serviceFilter);
          }
        } else if (item.filtered === false) {
          const filter = chipsOptions.find((x) => x.id === item.id);
          if (filter?.default === false) {
            chipsOptions = chipsOptions.filter((x) => x.key !== item.id);
          }
        }
      });

      dispatch({ type: CHANGE_CHIPS_FILTERS_LICENSES, payload: chipsOptions });
    }
  } catch (error) {
    console.error(error);
  }
};

export const getDetailLicensesCustomerAction = (
  dataRequest,
  loadFilters,
  enqueueSnackbar,
) => async (dispatch) => {
  try {
    dispatch({ type: LICENSE_LOADING, payload: true });
    const config = defaultHeaders();

    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/license/getDetailsLicenseCustomer`, dataRequest, config);
    if (response.status === 200) {
      const { records } = response.data.result;
      dispatch({ type: GET_DETAIL_LICENSES_CUSTOMER, payload: response.data.result.records });

      if (records.length > 0 && loadFilters) {
        records.map((item) => {
          const customer = item.customerInfo;
          item.serviceLicense.map((service) => {
            dispatch(setFiltersLicenseAction('Load', service, customer, null));
          });
        });
      }

      dispatch({ type: LICENSE_LOADING, payload: false });
    }
  } catch (error) {
    const { response } = error;
    dispatch({ type: LICENSE_LOADING, payload: false });
    enqueueSnackbar(response.status === 404
      ? 'No se encontraron licencias para este cliente en el filtro seleccionado'
      : 'La solicitud no ha podido ser procesada', {
      variant: response.status === 404
        ? 'warning'
        : 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    });
  }
};

export const clearLicensesCustomerAction = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_LICENSES_CUSTOMER, payload: [] });
    dispatch({ type: SET_CHIPS_FILTERS_LICENSES, payload: [] });
  } catch (error) {
    console.error(error);
  }
};

export const addNewLicenseCustomerAction = (
  dataRequest,
  enqueueSnackbar,
) => async (dispatch) => {
  try {
    dispatch({ type: LICENSE_LOADING, payload: true });
    const config = defaultHeaders();

    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/purchaseOrderLicense/AddNewCustomerLicense`, dataRequest, config);

    if (response.status === 200) {
      const { result } = response.data;
      dispatch({ type: ADD_LICENSE_CUSTOMER, payload: result.records });
      dispatch(
        setMessageAlertAction({
          category: 'createNewLicense',
          typeMsg: 'Success',
          tittleMsg: '¡Perfecto!',
          img: AddedLicenseSuccessfullyIcon,
          message: '¡Licencia añadida con éxito!',
          isOnlyMessage: true,
          autoHideAlert: true,
          showClose: false,
          showCloseAlert: false,
          showButtonCancel: true,
          popupStyle: { padding: '10%' },
          contentStyle: { display: 'grid', justifyItems: 'center' },
          styleMessage: { color: '#E45501', fontSize: 20 },
        }),
      );
      dispatch({ type: LICENSE_LOADING, payload: false });
    }
  } catch (error) {
    dispatch({ type: LICENSE_LOADING, payload: false });
    if (error.response) {
      if (error.response.status === 422) {
        enqueueSnackbar(error.response.data.result.error.errorDetail, {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar('Se ha presentado un error al añadir la licencia al cliente', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
  }
};

export const saveCustomerAction = (
  formData,
  appName,
  history,
  geoCodeService,
  ImagePopup,
) => async (dispatch) => {
  try {
    dispatch({ type: LICENSE_LOADING, payload: true });
    const customer = {
      personType: formData.personTypeService,
      documentTypeId: formData.documentTypeIdService,
      documentNumber: formData.documentNumberService,
      legalName: formData.personTypeService === 'J' ? formData.legalNameService : null,
      firstName: formData.firstNameService,
      lastName: formData.lastNameService,
      cellphoneNumber: formData.cellphoneNumberService,
      email: formData.emailService,
      departmentId: formData.departmentIdService.value
        ? formData.departmentIdService.value
        : formData.departmentIdService,
      cityId: formData.cityIdService.id
        ? formData.cityIdService.id
        : formData.cityIdService,
      fulladdress: formData.fulladdressService.description,
      legalDocumentTypeId: formData.legalDocumentTypeIdService,
      legalDocumentNumber: formData.legalDocumentNumberService,
      legalFirstName: formData.legalFirstNameService,
      legalLastName: formData.legalLastNameService,
      dv: formData.dvService,
      addressAdditionalInformation: formData.additionalInfoService,
      addressLatGeocode: geoCodeService ? geoCodeService.lat : null,
      addressLngGeocode: geoCodeService ? geoCodeService.lng : null,
      contractStartDate: formData.contractStartTime ? formData.contractStartTime.format() : null,
      contractEndDate: formData.contractEndTime ? formData.contractEndTime.format() : null,
      linkToApp: formData.linkToApp,
    };

    const config = defaultHeaders();
    const response = await axios.post(
      `${API_LICENCIAMIENTO}/license/api/Customer/SetCustomer`,
      customer, config,
    );

    const responseOrder = response.data;

    if (response.status === 200) {
      dispatch({ type: LICENSE_LOADING, payload: false });
      dispatch(
        setMessageAlertAction({
          category: 'license',
          typeMsg: 'Success',
          tittleMsg: '¡Perfecto!',
          img: ImagePopup,
          message: responseOrder.statusMessage,
          isOnlyMessage: true,
          autoHideAlert: true,
          showClose: false,
          showCloseAlert: false,
          showButtonCancel: true,
          popupStyle: { padding: '10%' },
          contentStyle: { display: 'grid', justifyItems: 'center' },
          styleMessage: { color: '#E45501', fontSize: 20 },
        }),
      );
    }
  } catch (error) {
    console.error(`Error al guardar el cliente: ${error.message}`);
    if (error.response) {
      if (error.response.status === 422) {
        dispatch(
          setMessageAlertAction({
            category: 'license',
            typeMsg: 'Error',
            tittleMsg: 'Error',
            message: '¡La empresa NO fue guardada!',
          }),
        );
      } else {
        dispatch(
          setMessageAlertAction({
            category: 'license',
            typeMsg: 'Error',
            tittleMsg: 'Error',
            message: '¡Error del servidor!',
            isOnlyMessage: true,
          }),
        );
      }
    }
  }
};

export const GetLicenseInformationRangesAction = (purchaseOrderLicenseId) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      purchaseOrderLicenseId,
    });
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/license/GetLicenseInformation`, config);
    if (response.status === 200) {
      dispatch({
        type: GET_LICENSE_INFORMATION,
        payload: response.data.result.records,
      });
    }
  } catch (error) {
    console.error(error);
    const { response } = error;
    if (response.status === 404) {
      dispatch({
        type: GET_LICENSE_INFORMATION,
        payload: response.data,
      });
    }
  }
};

export const SetLicenseTransactionRangeAction = (PurchaseOrderLicenseId, licenseTransactionRange, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: LICENSE_LOADING, payload: true });

    const saveRanges = {
      PurchaseOrderLicenseId,
      licenseTransactionRange,
    };

    const config = defaultHeaders();
    const response = await axios.post(
      `${API_LICENCIAMIENTO}/license/api/license/SetLicenseTransactionRange`,
      saveRanges, config,
    );

    if (response.status === 200) {
      dispatch(
        setMessageAlertAction({
          category: 'updateLicense',
          typeMsg: 'Success',
          tittleMsg: '¡Perfecto!',
          img: AddedLicenseSuccessfullyIcon,
          message: '¡Licencia actualizada con éxito!',
          isOnlyMessage: true,
          autoHideAlert: true,
          showClose: false,
          showCloseAlert: false,
          showButtonCancel: true,
          popupStyle: { padding: '10%' },
          contentStyle: { display: 'grid', justifyItems: 'center' },
          styleMessage: { color: '#E45501', fontSize: 20 },
        }),
      );
      dispatch({ type: LICENSE_LOADING, payload: false });
    }
  } catch (error) {
    const { response } = error;
    console.log('error', response);
    dispatch({ type: LICENSE_LOADING, payload: false });
    enqueueSnackbar(response.status === 422
      ? 'No se pudo actualizar la licencia al cliente.'
      : 'Se ha presentado un error al actualizar la licencia al cliente', {
      variant: response.status === 422
        ? 'warning'
        : 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    });
  }
};

export const getManagersAction = (role) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    Object.assign(config.headers, {
      role,
    });
    const response = await axios.get(`${API_SEGURIDAD}/api/user/get-users-by-role`, config);
    if (response.status === 200) {
      const { users } = response.data.result.recordsData[0];
      const formattedUsers = users.map((user) => ({
        value: user.sequence,
        label: `${user.names} ${user.surnames}`,
      }));
      dispatch({
        type: GET_MANAGERS,
        payload: formattedUsers,
      });
    }
  } catch (error) {
    console.error(error);
    dispatch({
      type: GET_MANAGERS,
      payload: [],
    });
  }
};

/**
 * Get a descrition when param is a object
 * @param {*} objectOrText
 * @returns address
 */
const getAddress = (objectOrText) => {
  if (typeof objectOrText === 'object' && objectOrText !== null && objectOrText !== undefined) {
    return objectOrText.description;
  }
  return objectOrText;
};

export const saveContractAction = (
  formData,
  appName,
  isRequiredSupervision,
) => async (dispatch) => {
  try {
    dispatch({ type: LICENSE_LOADING, payload: true });
    const contract = {
      contractNumber: formData.contractService,
      contractStartDate: formData.startDate.startOf('day').format(),
      contractEndDate: formData.endDate.endOf('day').format(),
      commercialManagerId: formData.gestorService,
      paymentCondition: formData.paymentService,
    };
    const customer = {
      personType: formData.personTypeService,
      documentTypeId: formData.documentTypeIdService,
      documentNumber: formData.documentNumberService,
      legalName: formData.personTypeService === 'J' ? formData.legalNameService : null,
      firstName: formData.personTypeService === 'N' ? formData.firstNameService : null,
      lastName: formData.personTypeService === 'N' ? formData.lastNameService : null,
      fulladdress: getAddress(formData.fulladdressService),
      cityId: formData.cityIdService.id
        ? formData.cityIdService.id
        : formData.cityIdService,
      cellphoneNumber: formData.cellphoneNumberService,
      email: formData.emailService,
    };
    const supervisor = {
      documentTypeId: formData.documentTypeIdSupervisor,
      documentNumber: formData.documentNumberSupervisor,
      firstName: formData.namesSupervisor,
      lastName: formData.lastNameSupervisor,
      fulladdress: getAddress(formData.CustomFulladdressService),
      cityId: formData.CustomCityIdService.id
        ? formData.CustomCityIdService.id
        : formData.CustomCityIdService,
      phoneNumber: formData.cellphoneNumberSupervisor,
      email: formData.emailSupervisor,
      jobTitle: formData.supervisorPosition,
    };
    const servicesToAddContract = {
      servicesToAdd: formData.servicesToAdd,
      serviceName: appName,
      requiresSupervision: isRequiredSupervision,
    };

    const registerContractRequest = {
      contract,
      customer,
      supervisor,
      servicesToAddContract,
    };

    const config = defaultHeaders();
    const response = await axios.post(
      `${API_LICENCIAMIENTO}/license/api/contract/create`,
      registerContractRequest, config,
    );
    if (response.status === 200) {
      dispatch({ type: LICENSE_LOADING, payload: false });
      dispatch(
        setMessageAlertAction({
          message: '¡Convenio guardado!',
          isSaved: true,
        }),
      );
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: LICENSE_LOADING, payload: false });
    dispatch(
      setMessageAlertAction({
        message: '¡Algo salió mal!',
        mensajeModal: 'Lo sentimos, el convenio no pudo ser guardado.',
        isSaved: false,
      }),
    );
  }
};

export const getAppParametersContractsAction = (appName, parameterType, validityType, startDateParameter, parameterTypeReassignmentQuota, parameterTypeValidityAmount) => async (dispatch) => {
  try {
    const config = defaultHeaders();
    const parameters = {
      DocumentTypeParameters: parameterType,
      ValidityType: validityType,
      StartDateParameter: startDateParameter,
    };
    Object.assign(config.headers, {
      appName,
      parameters: JSON.stringify(parameters),
      parameterTypeReassignmentQuota,
      parameterTypeValidityAmount,
    });
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/parameter/get-app-parameters-contracts`, config);
    if (response.status === 200) {
      const {
        listPaymentType = [],
        listDocumentType = [],
        listValidityType = [],
        listDeviceType = [],
        listCredentialDelivery = [],
        parameterMaxReassignmentQuota = 5,
        startDateParams = {},
        parameterMaxValidityAmount = [],
      } = response.data.result.records;
      const transformedListPaymentType = (listPaymentType ?? []).map((payment) => ({
        value: payment.paymentTypeId,
        label: payment.paymentTypeName,
      }));
      const transformedListValidityType = (listValidityType ?? []).map((validity) => ({
        value: validity.id,
        label: validity.nameValidity,
      }));
      const transformedListDeviceType = (listDeviceType ?? []).map((device) => ({
        value: device.id,
        label: device.nameDevice,
        codeDevice: device.codeDevice,
      }));
      const transformedListCredentialDelivery = (listCredentialDelivery ?? []).map((delivery) => ({
        value: delivery.id,
        label: delivery.nameDelivery,
      }));
      const transformedListMaxValidityAmount = (parameterMaxValidityAmount ?? []).map((validity) => ({
        value: validity.id,
        amount: validity.amount,
      }));
      const transformedStartDate = {
        isBeyondOneMonth: startDateParams?.isBeyondOneMonth ?? false,
        dateUnit: startDateParams?.dateUnit ?? 'days',
        quantity: startDateParams?.quantity ?? 0,
      };
      dispatch({
        type: GET_APP_PARAMETERS_CONTRACTS,
        listPaymentTypeContracts: transformedListPaymentType.length > 0 ? transformedListPaymentType : [],
        listDocumentTypeContracts: (listDocumentType ?? []).length > 0 ? listDocumentType : [],
        listValidityTypeContracts: transformedListValidityType.length > 0 ? transformedListValidityType : [],
        listDeviceTypeContracts: transformedListDeviceType.length > 0 ? transformedListDeviceType : [],
        listCredentialDeliveryContracts: transformedListCredentialDelivery.length > 0 ? transformedListCredentialDelivery : [],
        parameterMaxReassignmentQuota,
        startDateParams: transformedStartDate,
        transformedListMaxValidityAmountContracts: transformedListMaxValidityAmount.length > 0 ? transformedListMaxValidityAmount : [],
      });
    }
  } catch (error) {
    console.error(error);
    dispatch({
      type: GET_APP_PARAMETERS_CONTRACTS,
      listPaymentTypeContracts: [],
      listDocumentTypeContracts: [],
      listValidityTypeContracts: [],
      listDeviceTypeContracts: [],
      listCredentialDeliveryContracts: [],
      parameterMaxReassignmentQuota: 5,
      startDateParams: {
        isBeyondOneMonth: false,
        dateUnit: 'days',
        quantity: 0,
      },
      transformedListMaxValidityAmountContracts: [],
    });
  }
};

export const getContractDataAction = (contractNumber, serviceName) => async (dispatch) => {
  try {
    const config = defaultHeaders();

    const contractNumberRequest = {
      contractNumber,
      serviceName,
    };

    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/contract/get-contracts`, contractNumberRequest, config);
    if (response.status === 200) {
      dispatch({
        type: GET_CONTRACT_DATA,
        payload: {
          contractNumber: response.data.result.contractNumber,
          existContract: true,
        },
      });
    } else {
      dispatch({
        type: GET_CONTRACT_DATA,
        payload: {
          contractNumber: '',
          existContract: false,
        },
      });
    }
  } catch (error) {
    console.error(error);
    dispatch({
      type: GET_CONTRACT_DATA,
      payload: {
        contractNumber: '',
        existContract: false,
      },
    });
  }
};

export const getServicesDataDashboardAction = () => async (dispatch, getState) => {
  try {
    const customerInfoRequest = {
      documentType: '',
      documentNumber: '',
    };
    const {
      auth: { userInfo },
    } = getState();

    if (userInfo) {
      customerInfoRequest.documentType = userInfo.customer_type;
      customerInfoRequest.documentNumber = userInfo.customer_value;
    }

    dispatch({ type: LICENSE_LOADING, payload: true });
    const config = defaultHeaders();

    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/dashboard/dashboard-customer`, customerInfoRequest, config);

    if (response.status === 200) {
      dispatch({
        type: GET_SERVICES_DATA_DASHBOARD,
        payload: response.data.result,
      });
      dispatch({ type: LICENSE_LOADING, payload: false });
    } else {
      dispatch({
        type: GET_SERVICES_DATA_DASHBOARD,
        payload: [],
      });
      dispatch({ type: LICENSE_LOADING, payload: false });
    }
  } catch (error) {
    console.error(error);
    dispatch({
      type: GET_SERVICES_DATA_DASHBOARD,
      payload: [],
    });
    dispatch({ type: LICENSE_LOADING, payload: false });
  }
};

const createContractsObject = (data) => {
  const dataResult = data?.result;
  if (dataResult) {
    return {
      contractPackageId: `${dataResult.serviceName}-${dataResult.packageCode}`,
      quantityAccumulated: dataResult.quantityAccumulated,
      balanceAccumulated: dataResult.balanceAccumulated,
      usedAccumulated: dataResult.usedAccumulated,
      contractServiceDetail: dataResult.contractServiceDetail,
      isUnlimited: dataResult.isUnlimited,
    };
  }
  return {};
};

/**
 * Get detail contract of a specific product.
 * @param {*} ServiceName
 * @param {*} PackageCode
 * @returns a object with all details of contracts.
 */
export const getDetailContractsAction = (ServiceName, PackageCode) => async (dispatch, getState) => {
  const {
    auth: { userInfo },
  } = getState();
  const DocumentType = userInfo?.customer_type;
  const DocumentNumber = userInfo?.customer_value;
  if (DocumentType && DocumentNumber && ServiceName && PackageCode) {
    try {
      const config = defaultHeaders();
      const contractNumberRequest = {
        DocumentType,
        DocumentNumber,
        ServiceName,
        PackageCode,
      };
      const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/dashboard/contracted-service-details`, contractNumberRequest, config);
      if (response.status === 200) {
        const contracts = createContractsObject(response.data);
        dispatch({
          type: GET_DETAIL_CONTRACTS,
          payload: (() => {
            const prevState = getState().license?.detailContracts || [];
            const index = prevState.findIndex((item) => item.contractPackageId === contracts.contractPackageId);
            if (index !== -1) {
              const updatedData = [...prevState];
              updatedData[index] = contracts;
              return updatedData;
            }
            return [...prevState, contracts];
          })(),
        });
      }
    } catch (error) {
      console.warn('Error obtaining detail of contracts ', error);
    }
  }
  return null;
};
