/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import DeleteIcon from '@material-ui/icons/Delete';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFFF',
  },
  titleModalCart: {
    ...theme.home.cart.title,
    fontWeight: 700,
    fontSize: 25,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  tableItemsCart: {
    minHeight: '420px',
    maxHeight: '420px',
    overflow: 'auto',
    '@media (max-width: 1080px)': {
      minHeight: '280px',
      maxHeight: '420px',
    },
  },
  tableCotainer: {
    marginBottom: 20,
    borderRadius: 5,
  },
  table: {
    minWidth: 300,
  },
  tableHead: {
    ...theme.home.cart.tableHead,
    heigth: 35,
  },
  dialogContent: {
    marginBottom: 1,
  },
  promoForm: {
    ...theme.home.cart.promoForm,
    width: 'fit-content',
    display: 'flex',
    alignItems: 'flex-start',
    borderRadius: 5,
    padding: 15,
    marginTop: 15,
    marginBottom: 20,
  },
  divider: {
    marginBottom: 20,
  },
  totalWrapper: {
    float: 'right',
  },
  totalText: {
    ...theme.home.cart.totalText,
    fontSize: 20,
  },
  taxInclude: {
    fontSize: 14,
    color: '#B4B4B4',
    textAlign: 'right',
    marginBottom: 15,
  },
  btnPromotional: {
    ...theme.home.cart.btnPromotional,
  },
  tableCell: {
    ...theme.home.cart.tableCell,
    fontWeight: 900,
    padding: 8,
  },
  rowCell: {
    fontWeight: 700,
    cursor: 'default',
  },
  tableCellItems: {
    padding: 8,
    ...theme.home.cart.colorItem,
  },
  textPromotional: {
    ...theme.home.cart.textPromotional,
    marginRight: 10,
  },
  btnContinue: {
    ...theme.home.cart.btnContinue,
  },
  btnRemoveFromCart: {
    ...theme.home.cart.btnRemoveFromCart,
    padding: '0',
  },
  dvTitlePopup: {
    width: '100%',
  },
  btnAddProducts: {
    ...theme.home.cart.btnAddPackages,
    float: 'right',
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      ...theme.home.cart.hoverAddProducts,
    },
  },
  infoDescountText: {
    ...theme.home.cart.discountText,
    fontSize: 12,
  },
  observationText: {
    color: '#fff',
    fontSize: 14,
  },
  linkContact: {
    color: '#16B1F3',
    fontSize: 14,
  },
  quantityPackage: {
    ...theme.home.cart.quantityPackage,
  },
  quantityPackageArrowsVisible: {
    ...theme.home.cart.quantityPackage,
    '& .MuiInputBase-input': {
      // Mantiene las flechas visibles
      '-webkit-appearance': 'auto', // Mantiene la apariencia por defecto
    },
    '& .MuiInputBase-input:focus': {
      outline: 'none', // Elimina el contorno al enfocar
      boxShadow: 'none', // Quita cualquier sombra
    },
    // flechas sean visibles
    '& input[type="number"]::-webkit-inner-spin-button': {
      display: 'block', // Visible
      opacity: 0.6, // Opacidad visible
    },
    '& input[type="number"]::-webkit-outer-spin-button': {
      display: 'block', // Mantén visible
      opacity: 1, // Asegúrate de que sea visible
    },
    // Firefox
    '& input[type="number"]': {
    },
  },
  packageQuantityArrows: {
    ...theme.home.cart.quantityPackage,
    '& .MuiInputBase-input': {
      // Mantiene las flechas visibles
      '-webkit-appearance': 'auto', // Mantiene la apariencia por defecto
    },
    '& .MuiInputBase-input:focus': {
      outline: 'none', // Elimina el contorno al enfocar
      boxShadow: 'none', // Quita cualquier sombra
    },
    // flechas sean visibles
    '& input[type="number"]::-webkit-inner-spin-button': {
      display: 'block', // Visible
      opacity: 0.6, // Opacidad visible
      pointerEvents: 'none', // Desactivar interacción
    },
    '& input[type="number"]::-webkit-outer-spin-button': {
      display: 'block', // Mantén visible
      opacity: 1, // Asegúrate de que sea visible
      pointerEvents: 'none', // Desactivar interacción
    },
    // Firefox
    '& input[type="number"]': {
      pointerEvents: 'none', // Desactivar interacción
    },
  },
  tableCellNoItems: {
    '& .MuiTypography-body1': {
      ...theme.home.cart.tableCellNoItems,
    },
  },
}));
const TableModalExt = (props) => {
  const classes = useStyles();

  const {
    paymentCashApply, cart, cartClean, open, handleShowElement, handlePopoverOpen, handlePopoverClose,
    handleQuantity, quantityLicenseLocalEmployee, disabledFree, roundDecimalValue,
    getTotalByProduct, isShownId, handleDelete, EMPLOYEE_TYPE, appName,
  } = props;

  const getClassName = (row) => {
    if (appName === 'colnomina') {
      return row.isNotBaseEmployee
        ? classes.quantityPackageArrowsVisible
        : classes.packageQuantityArrows;
    }
    if (['firmaya', 'superguarda'].includes(appName)) {
      return classes.packageQuantityArrows;
    }
    return classes.quantityPackage;
  };
  return (
    <>
      <TableContainer className={classes.tableCotainer}>
        <Table className={classes.table}>
          <TableHead className={`${classes.tableHead} ${classes.headRow}`}>
            <TableRow>
              <TableCell className={classes.tableCell} align="left" style={{ width: '40%' }}>
                Producto
              </TableCell>
              <TableCell className={classes.tableCell} align="center" style={{ width: '10%', minWidth: '110px' }}>
                Cantidad
              </TableCell>
              <TableCell className={classes.tableCell} align="center" style={{ width: '15%' }}>
                Valor unitario
              </TableCell>
              <TableCell className={classes.tableCell} align="center" style={{ width: '10%' }}>
                Descuento
              </TableCell>
              <TableCell className={classes.tableCell} align="center" style={{ width: '10%' }}>
                IVA
              </TableCell>
              <TableCell className={classes.tableCell} align="center" style={{ width: '10%' }}>
                Subtotal
              </TableCell>
              <TableCell className={classes.tableCell} align="center" style={{ width: '5%' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {cart.length ? (
              cartClean.map((row) => (
                <TableRow
                  key={row.id}
                  onMouseEnter={() => handleShowElement(row.id)}
                  onMouseLeave={() => handleShowElement(0)}
                >
                  <TableCell component="th" scope="row" className={classes.tableCellItems}>
                    <Typography
                      aria-owns={
                        open ? 'mouse-over-popover' : undefined
                      }
                      aria-haspopup="true"
                      onMouseEnter={(e) => handlePopoverOpen(e, row)}
                      onMouseLeave={handlePopoverClose}
                      className={classes.rowCell}
                    >
                      {row.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" className={classes.tableCellItems}>

                    {row.isEmployee ? (
                      <FormControl fullWidth>
                        <TextField
                          label="#empleados"
                          size="small"
                          variant="outlined"
                          select
                          onChange={(e) => { handleQuantity(e, row, EMPLOYEE_TYPE); }}
                          name="quantity"
                          defaultValue={row.number}
                          style={{ textAlign: 'start', width: '100%' }}
                          className={classes.quantityPackage}
                        >
                          <MenuItem value="">
                            <em>Seleccione una opción</em>
                          </MenuItem>
                          {quantityLicenseLocalEmployee && quantityLicenseLocalEmployee.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    )
                      : (
                        <FormControl fullWidth>
                          <TextField
                            variant="outlined"
                            size="small"
                            type="number"
                            disabled={disabledFree(row)}
                            name="quantity"
                            autoComplete="off"
                            value={row.number}
                            inputProps={{
                              min: '1',
                              max: paymentCashApply,
                              step: '1',
                              style: {
                                textAlign: 'start', width: '100%', fontWeight: 700,
                              },
                            }}
                            onKeyDown={(e) => e.preventDefault()}
                            onChange={(e) => handleQuantity(e, row, null, 0)}
                            className={getClassName(row)}
                          />
                        </FormControl>
                      )}
                  </TableCell>
                  <TableCell align="right" className={`${classes.tableCellItems} ${classes.rowCell}`}>{`$${row.base.toLocaleString('en')}`}</TableCell>
                  <TableCell align="right" className={classes.tableCellItems}>{`$${roundDecimalValue(row.discount, 2).toLocaleString('en')}`}</TableCell>
                  <TableCell align="right" className={classes.tableCellItems}>{`${row.ivaValue}%`}</TableCell>
                  <TableCell align="right" className={`${classes.tableCellItems} ${classes.rowCell}`}>{getTotalByProduct(row)}</TableCell>
                  <TableCell
                    align="right"
                    className={classes.tableCellItems}
                    style={{ padding: '12px', textAlign: 'left' }}
                  >
                    {isShownId === row.id ? (
                      <Tooltip title="Eliminar">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={(e) => handleDelete(e, row)}
                          className={classes.btnRemoveFromCart}
                        >
                          <DeleteIcon
                            color="secondary"
                            className={classes.btnRemoveFromCart}
                          />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Typography
                        style={{ width: '10px' }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow key={0}>
                <TableCell scope="row" align="center" colSpan={7} className={classes.tableCellNoItems}>
                  <Typography
                    style={{ marginBottom: 10, fontWeight: 500 }}
                  >
                    ¡Tu carrito está vacío!
                  </Typography>
                  <AddShoppingCartIcon
                    color="primary"
                    style={{ fontSize: 70 }}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const mapStateToProps = () => ({

});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TableModalExt));
