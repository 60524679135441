/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as signalR from '@microsoft/signalr';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { LeftMenu } from '@dg-bucaramanga/react-components-dg-pre';
import jwtdecode from 'jwt-decode';
import Header from '../Header';
import { menuBackOfficeBuilt } from '../../../helpers/menuConfig';
import { API_SOCKET_ENDPOINT, API_SEGURIDAD } from '../../../config/config';
import AlertConfirm from '../AlertConfirm';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    marginRight: 10,
    backgroundColor: theme.leftMenu.backgroundColor,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    top: '64px',
    width: drawerWidth,
    background: 'none',
    borderRight: 'none',
  },
  content: {
    flexGrow: 1,
    padding: '0px',
  },
}));

// eslint-disable-next-line react/prop-types
const MasterPage = ({ component: Component, container, ...props }) => {
  const classes = useStyles();
  const { location } = props;
  const [menuReducer, setMenuReducer] = React.useState();
  const appName = useSelector(({ general }) => general.appName);
  const history = useHistory();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [homePage, setHomePage] = useState('');
  const [connectionId, setConnectionId] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [isCloseSession, setIsCloseSession] = useState(false);
  const [isCloseSessionMessage, setIsCloseSessionMessage] = useState('');
  const [token, setToken] = useState({
    result: {
      token: '',
    },
    error: {},
  });
  const jsonColorMenu = {
    primaryColor: theme.leftMenu.color,
    secondaryColor: '#ffff',
    buttonColor: '#ccfc',
    textButtonColor: '#ffa',
  };

  const getToken = (currentToken) => {
    let jwt;
    if (currentToken) {
      jwt = currentToken;
    } else {
      jwt = sessionStorage.getItem('appsUserToken');
    }

    if (jwt) {
      const jwtToken = jwt.replace('Bearer ', '');
      setToken((oldState) => ({
        ...oldState,
        result: {
          ...oldState.result,
          token: jwtToken,
        },
      }));
      const user = jwtdecode(jwtToken);
      if (user.APPS === 'LICENSE') {
        const rol = user.LICENSE_ROLE;
        const isArrayRoles = Array.isArray(rol);
        if (!isArrayRoles) {
          if (user.LICENSE_ROLE === 'LICFINANCIERO' || user.LICENSE_ROLE === 'LICFINANCIEROCONSULTA') {
            setHomePage('/admin/payments');
          } else if (user.LICENSE_ROLE?.includes('_COMERCIALMANAGER')) {
            setHomePage('/admin/agreements');
          } else {
            setHomePage('/admin/configurations');
          }
        } else if (rol.some((role) => role === 'SUPERADMIN' || role === 'LICENSECOMERCIAL' || role === 'LICENSEPROMOCODE')) {
          setHomePage('/admin/configurations');
        } else if (rol.some((role) => role === 'LICFINANCIERO' || role === 'LICFINANCIEROCONSULTA')) {
          setHomePage('/admin/payments');
        } else if (rol.some((role) => role.includes('_COMERCIALMANAGER'))) {
          setHomePage('/admin/agreements');
        } else {
          setHomePage('/admin/configurations');
        }
      }
      return true;
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const menuReducerBuilt = menuBackOfficeBuilt;
    setMenuReducer(menuReducerBuilt);
    getToken();
  }, []);

  useEffect(() => {
    if (homePage.includes('/admin/')) {
      const protocol = new signalR.JsonHubProtocol();
      const transport = signalR.HttpTransportType.WebSockets;

      const options = {
        transport,
        skipNegotiation: true,
        logMessageContent: true,
      };

      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${API_SOCKET_ENDPOINT}/api/connections/session`, options)
        .withHubProtocol(protocol)
        .withAutomaticReconnect()
        .build();

      connection.start()
        .then(() => {
          setConnectionId(connection.connectionId);
          setIsConnected(true);

          connection.invoke('GetConnectionId').then((pConnectionId) => {
            setConnectionId(pConnectionId);
          });

          connection.on('ReceiveSessionStatus', (message) => {
            setIsCloseSession(true);
            setIsCloseSessionMessage(message.message);
            connection.stop().then(() => {
              setTimeout(() => {
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = '/';
              }, 2000);
              console.log('Cierra la conexión del websocket');
            }).catch((err) => {
              console.error('Error closing connection', err);
            });
          });
        })
        .catch((e) => console.log('Connection failed: ', e));
    }
  }, [homePage]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (homePage.includes('/admin/')) {
      if (connectionId && isConnected && !isValidated) {
        const timeoutId = setTimeout(() => {
          const tokenSession = sessionStorage.getItem('appsUserToken');

          const validateSession = async () => {
            try {
              await fetch(`${API_SEGURIDAD}/api/user/validatesession`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: tokenSession,
                  connectionId,
                },
              });
              setIsValidated(true);
            } catch (e) {
              console.error('Error al validar la sesión:', e);
            }
          };

          validateSession();
        }, 100);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [connectionId, isConnected, isValidated]);

  const handleRedirectAgreements = () => {
    history.push('/admin/agreements');
  };

  const drawMenu = () => (
    <>
      <div>
        {token.result.token && !!menuReducer && menuReducer.length > 0 ? (
          <>
            <Hidden xsDown>
              <LeftMenu
                open
                token={token.result.token}
                appName="license"
                drawerProps={{
                  variant: 'permanent',
                  open: true,
                  classes: {
                    paper: classes.drawer,
                  },
                }}
                version=""
                functions={[{ name: 'handleRedirectAgreements', function: handleRedirectAgreements }]}
                color={jsonColorMenu}
              />
            </Hidden>
            <Hidden smUp>
              <LeftMenu
                open
                token={token.result.token}
                appName="license"
                drawerProps={{
                  variant: 'temporary',
                  anchor: theme.direction === 'rtl' ? 'right' : 'left',
                  open: mobileOpen,
                  onClose: handleDrawerToggle,
                  ModalProps: {
                    keepMounted: true,
                  },
                  classes: {
                    paper: classes.drawer,
                  },
                }}
                version=""
                functions={[]}
              />
            </Hidden>
          </>
        ) : null}
      </div>
    </>
  );

  return (
    <>
      {appName === 'license' ? (
        <>
          <div className={classes.root}>
            <CssBaseline />
            <Header />
            {
              location.pathname.toLowerCase() !== '/myplan'
                ? (
                  <nav
                    className={`${classes.drawer} 'custom-drawer'`}
                    aria-label="mailbox folders"
                  >

                    <Hidden xsDown implementation="css">
                      <Drawer
                        classes={{
                          paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                      >
                        {drawMenu()}
                      </Drawer>
                    </Hidden>
                  </nav>
                )
                : ''
            }

            <main className={classes.content}>
              <div className={classes.toolbar} />
              <br />
              <br />
              <br />
              {location.pathname === '/' ? (
                history.push(homePage)
              ) : (
              // eslint-disable-next-line react/jsx-props-no-spreading
                <Component {...props} />
              )}
            </main>
          </div>
          {isCloseSession && (
          <AlertConfirm
            isOpen={isCloseSession}
            mensajeModal={isCloseSessionMessage}
            isOnlyMessage
            showClose={false}
          />
          )}
        </>
      ) : (
        <>
          {location.pathname.includes('/admin/') ? (
            history.push('/')
          ) : (
            <>
              <Header />
              <Component {...props} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default MasterPage;
