import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CustomTable from '../../../components/form/Table/CustomTable';
import { getDetailContractsAction } from '../../../actions/license.action';
import {
  URL_REPORT_CONSUMO,
} from '../../../config/config';

const getMulishText = (fontWeight, color) => ({
  fontFamily: 'Mulish, sans-serif',
  fontSize: '14px',
  fontWeight,
  color,
});

const useStyles = makeStyles(() => ({
  tableHead: {
    backgroundColor: '#009BDD',
    borderTopRightRadius: '5px',
  },
  tableHeadItem: {
    ...getMulishText(700, 'white'),
  },
  infoText: {
    ...getMulishText(700, '#4F4F4F'),
    paddingLeft: '5%',
  },
  tableContainer: {
    paddingTop: '25px',
  },
  button: {
    backgroundColor: '#E55200',
    paddingLeft: '19px',
    paddingRight: '19px',
    paddingBottom: '8px',
    paddingTop: '8px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#E55200',
    },
  },
  textButton: {
    ...getMulishText(700, 'white'),
  },
}));

/**
 * View into products, show a detail of use contracts
 * @param {*} props
 * @returns
 */
const DetailContract = (props) => {
  const classes = useStyles();
  const {
    ServiceName, PackageCode, getDetailContracts, detailContracts,
  } = props;
  const columnData = [
    {
      id: 'contractNumber',
      label: 'Contrato',
      minWidth: 100,
      position: 1,
      isSortable: true,
      sortPropertyName: 'contractNumber',
    },
    {
      id: 'contractStartDate',
      label: 'Fecha inicio',
      minWidth: 100,
      position: 2,
      isSortable: true,
      sortPropertyName: 'contractStartDate',
    },
    {
      id: 'contractEndDate',
      label: 'Fecha vencimiento',
      minWidth: 100,
      position: 3,
      isSortable: true,
      sortPropertyName: 'contractEndDate',
    },
    {
      id: 'totalAmmount',
      label: 'Total',
      minWidth: 100,
      position: 4,
      isSortable: true,
      sortPropertyName: 'totalAmmount',
    },
    {
      id: 'totalBalance',
      label: 'Disponibles',
      minWidth: 100,
      position: 5,
      isSortable: true,
      sortPropertyName: 'totalBalance',
    },
    {
      id: 'totalUsed',
      label: 'Usados',
      minWidth: 100,
      position: 6,
      isSortable: true,
      sortPropertyName: 'totalUsed',
    },
  ];
  const [contracts, setContracts] = useState([]);
  const [contractInfo, setContractInfo] = useState({});
  const [loading, setLoading] = useState(true);
  /**
   * Add dot to a number for example 3000 --> 3.000
   * @param {*} number simple number
   * @returns format number
   */
  const formatNumber = (number) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1.';
    return number.toString().replace(exp, rep);
  };

  const loadData = (data) => {
    setContractInfo({
      quantityAccumulated: formatNumber(data?.quantityAccumulated),
      balanceAccumulated: formatNumber(data?.balanceAccumulated),
      usedAccumulated: formatNumber(data?.usedAccumulated),
      isUnlimited: data.isUnlimited,
    });
    const updatedContracts = data.contractServiceDetail?.map(
      (item) => ({
        ...item,
        contractStartDate: item.effectiveDate
          ? new Date(item.effectiveDate).toLocaleDateString('es-ES')
          : '',
        contractEndDate: item.expirationDate
          ? new Date(item.expirationDate).toLocaleDateString('es-ES')
          : '',
        totalAmmount: item.isUnlimited ? 'Ilimitado' : formatNumber(item.totalAmmount),
        totalBalance: item.isUnlimited ? '-' : formatNumber(item.totalBalance),
        totalUsed: item.isUnlimited ? '-' : formatNumber(item.totalUsed),
        state: 0,
      }),
    );
    setContracts(updatedContracts);
  };

  const handleClickMovements = () => {
    window.open(URL_REPORT_CONSUMO, '_blank');
  };

  useEffect(() => {
    if (detailContracts && detailContracts.length > 0) {
      const index = detailContracts.findIndex(
        (item) => item.contractPackageId === `${ServiceName}-${PackageCode}`,
      );
      const currentContract = detailContracts[index];
      if (currentContract) {
        setLoading(false);
        loadData(currentContract);
      }
    }
    // eslint-disable-next-line
  }, [detailContracts, ServiceName, PackageCode]);

  useEffect(() => {
    setLoading(true);
    getDetailContracts(ServiceName, PackageCode);
  }, [ServiceName, PackageCode, getDetailContracts]);

  return (
    <>
      {(loading) ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{ padding: '20px' }}>
          <Grid container sx={{ minHeight: '100vh', padding: 2 }}>
            <Grid item xs={4}>
              <Typography className={classes.infoText}>
                Total:
                <br />
                <div style={{ color: '#1D71B8' }}>
                  { contractInfo?.isUnlimited ? 'Ilimitado' : contractInfo?.quantityAccumulated}
                </div>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.infoText}>
                Disponible:
                <br />
                <div style={{ color: '#1D71B8' }}>
                  { contractInfo?.isUnlimited ? '-' : contractInfo?.balanceAccumulated}
                </div>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.infoText}>
                Usado:
                <br />
                <div style={{ color: '#1D71B8' }}>
                  {contractInfo?.isUnlimited ? '-' : contractInfo?.usedAccumulated}
                </div>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <div style={{ paddingLeft: '25%' }}>
                <Button className={classes.button} onClick={handleClickMovements}>
                  <Typography className={classes.textButton}>
                    Movimientos
                  </Typography>
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} className={classes.tableContainer}>
              <CustomTable
                columns={columnData}
                data={contracts}
                enablePagination={false}
                styleTableContainer={{ marginTop: '15px', width: 'auto' }}
                styleTableCell={{
                  ...getMulishText(400, '#000000'),
                  textAlign: 'left',
                }}
                styleTableHead={{ textAlign: 'left' }}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (props) => ({
  getDetailContracts: props.license.getDetailContracts,
  detailContracts: props.license.detailContracts,
});

const mapDispatchToProps = {
  getDetailContracts: getDetailContractsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailContract);
