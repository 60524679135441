import {
  Grid, makeStyles, Paper, Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const getMulishText = (fontWeight, color, fontSize) => ({
  fontFamily: 'Mulish, sans-serif',
  fontSize,
  fontWeight,
  color,
});

const useStyles = makeStyles(() => ({
  paperStyle: {
    ...getMulishText(700, '#575756', '14px'),
    padding: '20px',
    boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.2)',
  },
  sumaryStyle: {
    ...getMulishText(700, '#1D71B8', '18px'),
    marginBottom: '20px',
  },
  resultText: {
    ...getMulishText(500, '#575756', '14px'),
  },
  serviceStyle: {
    ...getMulishText(700, '#000000', '14px'),
    marginBottom: '20px',
    marginTop: '20px',
  },
  titleSerive: {
    ...getMulishText(700, '#575756', '14px'),
    marginBottom: '10px',
  },
}));

const QuickSummary = (props) => {
  const classes = useStyles();
  const { dataForm } = props;
  const [summary, setSummary] = useState({});

  const getCurrentDocumentType = () => {
    if (dataForm?.documentTypes?.length > 0 && dataForm?.documentTypeIdService) {
      return dataForm.documentTypes.find(
        (element) => element.id === dataForm.documentTypeIdService,
      )?.text;
    }
    return 'No disponible';
  };

  const getCompanyName = () => {
    if (dataForm?.documentTypeIdService === 4) {
      return dataForm?.legalNameService;
    }
    if (dataForm?.firstNameService && dataForm?.lastNameService) {
      return `${dataForm.firstNameService} ${dataForm.lastNameService}`;
    }
    return 'No disponible';
  };

  useEffect(() => {
    if (dataForm) {
      setSummary({
        oportunity: dataForm.contractService || 'No disponible',
        startDate: dataForm.startDate
          ? new Date(dataForm.startDate).toLocaleDateString('es-ES')
          : 'No disponible',
        endDate: dataForm.endDate
          ? new Date(dataForm.endDate).toLocaleDateString('es-ES')
          : 'No disponible',
        identificationType: getCurrentDocumentType(),
        identificationNumber: dataForm.documentNumberService || 'No disponible',
        companyName: getCompanyName(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm]);

  const getResultText = (text) => (
    <Typography className={classes.resultText} component="span">
      {text}
    </Typography>
  );

  /**
   * Add dot to a number for example 3000 --> 3.000
   * @param {*} number simple number
   * @returns format number
   */
  const formatNumber = (number) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1.';
    return number.toString().replace(exp, rep);
  };

  const getServiceList = () => {
    if (dataForm?.serviceList?.length > 0 && dataForm?.productType?.length > 0) {
      const elements = [];

      for (let i = 0; i < dataForm.serviceList.length; i += 1) {
        const service = dataForm.serviceList[i];
        const paddingStyle = i % 2 === 0 ? { paddingLeft: '0px' } : { paddingRight: '0px' };
        elements.push(
          <Grid item xs={12} sm={6} style={paddingStyle}>
            <Paper className={classes.paperStyle}>
              <Typography className={classes.titleSerive}>
                {dataForm.productType.find(
                  (product) => product.value === service.productService,
                )?.label || 'Desconocido'}
              </Typography>
              {'\u00A0• Cantidad: '}
              {getResultText(service.isSwitchEnabled ? 'Ilimitada' : formatNumber(service.quantityToAddService))}
              <br />
              {'\u00A0• Tipo vigencia: '}
              {getResultText(dataForm.listValidityTypeContracts.find(
                (validity) => validity.value === service.validityService,
              )?.label || 'Desconocido') }
              <br />
              {'\u00A0• Cantidad vigencia: '}
              {getResultText(service?.validityAmount)}
            </Paper>
          </Grid>,
        );
      }
      return <>{elements}</>;
    }
    return <></>;
  };

  return (
    <div style={{ width: '100%', minHeight: '20vw', paddingBottom: '30px' }}>
      <Grid container spacing={3} alignItems="center">
        <Grid xs={12}>
          <Typography className={classes.sumaryStyle}>Resumen</Typography>
        </Grid>
        <Grid xs={12}>
          <Paper className={classes.paperStyle}>
            {'Número de oportunidad: '}
            {getResultText(summary?.oportunity)}
            <br />
            {'Fecha inicio: '}
            {getResultText(summary?.startDate)}
            <br />
            {'Fecha fin: '}
            {getResultText(summary?.endDate)}
            <br />
            {'Tipo de identificación: '}
            {getResultText(summary?.identificationType)}
            <br />
            {'No. Identificación: '}
            {getResultText(summary?.identificationNumber)}
            <br />
            {dataForm?.documentTypeIdService === 4 ? 'Razón social: ' : 'Nombres y apellidos: ' }
            {getResultText(summary?.companyName)}
          </Paper>
        </Grid>
        <Grid xs={12}>
          <Typography className={classes.serviceStyle}>Servicio(s)</Typography>
        </Grid>
        {getServiceList()}
      </Grid>
    </div>
  );
};

export default QuickSummary;
